@if(selectedChannel()){
<div class="d-flex flex-column h-100">
  <as-split
    class="pt-2"
    direction="vertical"
    unit="percent"
    (transitionEnd)="splitDragEnd()"
    (dragEnd)="splitDragEnd()"
  >
    <as-split-area class="channel-area" size="50">
      @if(responsiveUiService.md()){
      <as-split
        class="split-channel-panels"
        direction="horizontal"
        unit="percent"
        (transitionEnd)="splitDragEnd()"
        (dragEnd)="splitDragEnd()"
      >
        <as-split-area size="28" minSize="28" maxSize="35">
          <ng-container
            *ngTemplateOutlet="channelSettingsTemplate"
          ></ng-container>
        </as-split-area>
        <as-split-area size="72">
          <ng-container
            *ngTemplateOutlet="deviceManagerPanelTemplate"
          ></ng-container>
        </as-split-area>
      </as-split>
      } @else {
      <div class="px-2 pt-3 d-flex flex-column h-100">
        <ul ngbNav #nav="ngbNav" class="mx-2 nav-pills">
          <li ngbNavItem>
            <a ngbNavLink>{{ 'CHANNEL_SETTINGS' | translate }}</a>
            <ng-template ngbNavContent>
              <ng-container
                *ngTemplateOutlet="channelSettingsTemplate"
              ></ng-container>
            </ng-template>
          </li>
          <li ngbNavItem>
            <a ngbNavLink>{{ 'DEVICE_SETTINGS' | translate }}</a>
            <ng-template ngbNavContent>
              <ng-container
                *ngTemplateOutlet="deviceManagerPanelTemplate"
              ></ng-container>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="overflow-auto"></div>
      </div>
      }
    </as-split-area>
    <as-split-area class="d-flex flex-column pt-1" size="50">
      @if(responsiveUiService.md()){
      <div class="layout-playlist-settings h-100">
        <ng-container *ngTemplateOutlet="layoutSelector"></ng-container>
        <ng-container *ngTemplateOutlet="channelPlaylists"></ng-container>
      </div>
      }@else {
      <ul ngbNav #nav="ngbNav" class="mx-2 nav-pills">
        <li ngbNavItem>
          <a ngbNavLink>{{ 'PLAYLISTS' | translate }}</a>
          <ng-template ngbNavContent>
            <ng-container *ngTemplateOutlet="channelPlaylists"></ng-container>
          </ng-template>
        </li>
        <li ngbNavItem>
          <a ngbNavLink>{{ 'LAYOUT' | translate }}</a>
          <ng-template ngbNavContent>
            <ng-container *ngTemplateOutlet="layoutSelector"></ng-container>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav" class="overflow-auto"></div>
      }
    </as-split-area>
  </as-split>
</div>
} @else {
<div class="deviceDetails container-fluid content-container-card">
  <h4 class="col text-center">
    {{ 'CHANNEL_DOES_NOT_EXIST' | translate }}
  </h4>
</div>
}

<!-- MARK: Device Manage Panel -->
<ng-template #deviceManagerPanelTemplate>
  @if(loadingDevices){
  <app-loader />
  } @else { @if(devicesConnectedToChannel().length === 0){
  <div
    class="d-flex flex-column align-items-center justify-content-center h-100"
  >
    <h3 class="my-3">
      {{
        isChannel()
          ? ('NO_CHANNEL_SCREEN_FOUND' | translate)
          : ('NO_EVENT_SCREEN_FOUND' | translate)
      }}
    </h3>
    @if(isChannel()){
    <button
      class="btn btn-outline-primary mb-2"
      (click)="provisionDevice(); $event.stopPropagation()"
    >
      {{ 'ADD_SCREEN' | translate }}
    </button>
    <button
      class="btn btn-outline-primary"
      (click)="connectExistingDevices('channel')"
      ngbTooltip="{{ 'CONNECT_DEVICE_TO_CHANNEL_TT' | translate }}"
    >
      {{ 'CONNECT_DEVICE_TO_CHANNEL' | translate }}</button
    >} @if(isEvent()){
    <button
      class="btn btn-outline-primary"
      (click)="connectExistingDevices('event')"
      ngbTooltip="{{ 'ADD_EVENT_SCREEN_TT' | translate }}"
    >
      {{ 'ADD_EVENT_SCREEN' | translate }}
    </button>
    }
  </div>

  }@else {
  <div class="p-2 h-100">
    @if(isChannel() && singleDeviceChannel()){ @defer(when selectedDevice()){
    <app-device-manage
      #deviceManageComponent
      class="device-details"
      [device]="selectedDevice()!"
      [channelParent]="true"
      (closeClick)="showDeviceList()"
      (reloadParent)="refreshDevices()"
    ></app-device-manage
    >} } @else { @if(isChannel() && selectedDevice()){
    <app-device-manage
      #deviceManageComponent
      class="device-details"
      [device]="selectedDevice()!"
      [showCloseButton]="devicesConnectedToChannel().length > 1"
      [channelParent]="true"
      (closeClick)="showDeviceList()"
      (reloadParent)="refreshDevices()"
    ></app-device-manage>
    } @else {
    <div class="d-flex justify-content-between align-items-center">
      @if(!responsiveUiService.isMobileDevice()){
      <h4>
        {{
          channelId()
            ? ('DEVICES_CONNECTED_TO_THIS_CHANNEL' | translate)
            : ('DEVICES_CONNECTED_TO_THIS_PROFILE' | translate)
        }}
      </h4>
      } @if(isChannel()){
      <button
        class="btn btn-outline-primary"
        (click)="provisionDevice(); $event.stopPropagation()"
      >
        {{ 'ADD_SCREEN' | translate }}</button
      >} @if(isEvent()){
      <button
        class="btn btn-outline-primary"
        (click)="connectExistingDevices()"
        ngbTooltip="{{ 'ADD_EVENT_SCREEN_TT' | translate }}"
      >
        {{ 'ADD_EVENT_SCREEN' | translate }}
      </button>
      }
    </div>
    <designage-data-table
      configId="channel-devices-list"
      [data]="devicesConnectedToChannel()"
      [columns]="deviceColumns"
      [alwaysSort]="true"
      [showFooter]="false"
      [customComponent]="[deviceTime, deviceTimeAgo]"
      (rowClick)="onDeviceClick($event)"
      [showMultiSelect]="isEvent()"
      [(selectedRows)]="selectedDevices"
      [rowActiveInSlidingPanel]="selectedDeviceId$ | async"
    />}
    <ng-template #deviceTime let-row>
      <table-dateTime
        [data]="row.deviceInfo?.currentTime?.currentDate"
        [timezoneOffset]="row.timezoneOffset"
      ></table-dateTime>
    </ng-template>
    <ng-template #deviceTimeAgo let-row>
      <table-dateTime
        [data]="row.lastPing"
        [pipeType]="'amTimeAgo'"
        [tooltipPipeArgs]="'medium'"
      ></table-dateTime>
    </ng-template>
    @if(isEvent()){
    <div class="d-flex justify-content-end align-items-center w-100">
      @if(selectedDevices.length > 0){
      <button
        class="btn btn-outline-danger"
        (click)="removeSelectedDevices()"
        ngbTooltip="{{ 'REMOVE_EVENT_SCREEN_TT' | translate }}"
      >
        {{ 'REMOVE_EVENT_SCREEN' | translate }}
      </button>
      }
    </div>
    } }
  </div>
  } }
</ng-template>

<!-- MARK: Channel Settings -->
<ng-template #channelSettingsTemplate>
  @if(loadingChannelSettings){
  <app-loader class="channelSettings" />
  } @else {
  <div class="position-relative inspector-panel">
    <app-channel-settings
      class="gridChannelSettings"
      [channel]="selectedChannel()"
      [isDashboardView]="true"
      headerTitle="SETTINGS"
      [profileId]="profileId"
      (channelUpdated)="selectUpdatedChannel($event)"
    />
    @if(isUserSuperAdmin){
    <button
      class="btn btn-dark position-absolute bottom-0"
      [ngbPopover]="debugPopOver"
      container="body"
      popoverClass="debug-popover"
    >
      Admin debug</button
    >}
  </div>
  }
</ng-template>

<!-- MARK: Layout Explorer -->
<ng-template #layoutSelector>
  <div class="d-flex align-items-center p-2 position-relative">
    <h4 class="m-0 px-2">
      {{ 'LAYOUT_CONNECTED_TO_CHANNEL' | translate }}
    </h4>
    @if(selectedPlaylistRegion() != null){
    <div
      class="position-absolute px-2 d-flex align-items-center"
      style="top: 2.5rem; z-index: 5"
    >
      <h5 class="m-0 pe-3">
        {{ 'SELECTED_REGION' | translate }}:
        {{
          selectedPlaylistRegion() != ''
            ? selectedPlaylistRegion()
            : ('REGION_CANVAS_ROOT' | translate)
        }}
      </h5>
      <button
        class="btn btn-sm btn-outline-primary"
        (click)="clearRegionSelection()"
      >
        {{ 'CLEAR_SELECTION' | translate }}
      </button>
    </div>
    }
  </div>
  <div class="p-2 overflow-hidden">
    <app-channel-form
      class="d-flex flex-column justify-content-center h-100"
      [formId]="'playlistLayout'"
      [parentFormGroup]="channelForm"
      [profileId]="profileId"
      [layout]="selectedChannel()?.layout"
      [showSelectionOnly]="true"
      [settingsManager]="false"
      [layoutManager]="true"
      [channel]="selectedChannel()"
      [isDashboardView]="true"
      [canSelectRegions]="true"
      [canSetLayout]="false"
      [showMiniLayoutExplorer]="false"
      [explorerSize]="explorerSize"
      [selectedRegion]="selectedPlaylistRegion()"
      (valid)="isFormValid = $event"
      (pristine)="isFormPristine = $event"
      (submitted)="updateChannel($event)"
      (values)="values = $event"
      (explorerSelectedRegion)="onExplorerSelectedRegion($event)"
    ></app-channel-form>
  </div>
</ng-template>

<!-- MARK: Playlists -->
<ng-template #channelPlaylists>
  <div class="d-flex align-items-end p-2">
    <h4 class="m-0 px-2">
      {{ 'PLAYLISTS_CONNECTED_TO_CHANNEL' | translate }}
    </h4>
  </div>
  <div class="overflow-auto">
    @if(selectedChannel() ){
    <!-- {{ playlistsConnectedToChannel() | json }} -->
    <app-playlist-list-for-channel
      [playlistRegions]="selectedChannel()?.playlistRegions"
      [selectedPlaylistRegion]="selectedPlaylistRegion()"
      [loading]="loadingPlaylists()"
      [profileId]="profileId()"
      [playlistsConnectedToChannel]="playlistsConnectedToChannel()"
      [deviceId]="selectedDevice()?.id"
      [channel]="selectedChannel()"
      (refreshPlaylists)="refreshPlaylists()"
    ></app-playlist-list-for-channel>
    }
  </div>
</ng-template>

<ng-template #debugPopOver>
  <table class="table table-sm debug-table">
    <tbody>
      <tr>
        <td>apiUrl:</td>
        <td>
          {{ apiUrl }}
          <copy-to-clipboard [cbcontent]="apiUrl"></copy-to-clipboard>
        </td>
      </tr>
      <tr>
        <td>dataUrl:</td>
        <td>
          {{ dataBucketUrl }}
          <copy-to-clipboard [cbcontent]="dataBucketUrl"></copy-to-clipboard>
        </td>
      </tr>
      <tr>
        <td>channelId:</td>
        <td>
          {{ channelId() }}
          <copy-to-clipboard [cbcontent]="channelId"></copy-to-clipboard>
        </td>
      </tr>
      <tr>
        <td>profileId:</td>
        <td>
          {{ profileId() }}
          <copy-to-clipboard [cbcontent]="profileId"></copy-to-clipboard>
        </td>
      </tr>
      <tr>
        <td>Channel SMIL playlists (S3):</td>
        <td>
          <a href="{{ s3smilPlaylistsUrl }}" target="__BLANK"
            >Click to download</a
          >
        </td>
      </tr>
      <tr>
        <td>Channel playlists (S3):</td>
        <td>
          <a href="{{ s3playlistsUrl }}" target="__BLANK">Click to download</a>
        </td>
      </tr>
      <tr>
        <td>Channel layout (S3):</td>
        <td>
          <a href="{{ s3LayoutUrl }}" target="__BLANK">Click to download</a>
        </td>
      </tr>
      <tr>
        <td>Channel playlists (API):</td>
        <td>
          <a
            href="{{ apiUrl }}/api/v1/playlists/channel/{{ channelId }}"
            target="__BLANK"
            >Click to download</a
          >
        </td>
      </tr>
    </tbody>
  </table>
</ng-template>
