<div
  class="
    container-fluid
    account-settings-container
    content-container-card
    p-5
    mb-5
  "
>
  <div class="row">
    <div class="col-12 text-center">
      <img
        [src]="logoSrc"
        class="logo mx-auto d-block"
        alt="{{ 'LOGO' | translate }}"
      />
      <h2>{{ 'REGISTRATION_COMPLETE' | translate }}</h2>
      <h5 class="info py-4">{{ 'YOU_CAN_NOW_LOGIN' | translate }}</h5>
      <div class="login-button">
        <button
          (click)="login()"
          class="login-button btn btn-lg btn-block btn-outline-primary"
        >
          {{ 'PROCEED_TO_LOGIN' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
