<div class="overflow-auto inspector-panel">
  <div class="p-2">
    <app-channel-form
      class="channelForm"
      [isDashboardView]="isDashboardView"
      [formId]="'channelForm'"
      [profileId]="profileId"
      [layout]="channel?.layout"
      [channel]="channel"
      [layoutManager]="true"
      [showSelectionOnly]="true"
      (valid)="isFormValid = $event"
      (pristine)="isFormPristine = $event"
      (submitted)="updateChannel($event)"
      (loading)="onLoadingChanged($event)"
      (loaderMessage)="onLoaderMessageChanged($event)"
    ></app-channel-form>
  </div>
  <div class="d-flex justify-content-end p-2">
    <button
      form="channelForm"
      [disabled]="!saveEnabled"
      [class.btn-sm]="responsiveUiService.isMobileDevice()"
      class="btn btn-outline-warning me-2"
      (click)="resetForm()"
    >
      {{ 'CANCEL' | translate }}
    </button>

    <button
      form="channelForm"
      type="submit"
      [disabled]="!saveEnabled"
      [class.btn-sm]="responsiveUiService.isMobileDevice()"
      [class.btn-outline-success]="isFormPristine"
      [class.btn-success]="!isFormPristine"
      class="btn"
    >
      {{ 'SAVE' | translate }}
    </button>
  </div>
</div>
<div class="pt-2 border-top d-flex align-items-end justify-content-end">
  @if(showDeleteButton){
  <button
    [class.btn-sm]="!responsiveUiService.isBrowserRes()"
    class="btn btn-outline-danger text-light"
    (click)="openDeleteChannelDialog()"
    [disabled]="deletingChannel"
  >
    @if(deletingChannel){
    <div
      class="spinner-border spinner-border-sm"
      role="status"
      aria-hidden="true"
    >
      <span class="visually-hidden">{{ 'DEPROVISIONING' | translate }}...</span>
    </div>
    }@else {
    {{ 'DELETE_CHANNEL' | translate }}
    }</button
  >}
</div>
