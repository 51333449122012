import {
  Component,
  input,
  ChangeDetectionStrategy,
  computed,
} from '@angular/core';
import { MediaMetadata, ResourceType } from '@designage/gql';
import { CopyToClipboardComponent } from '@desquare/components/common/src/copy-to-clipboard/copy-to-clipboard.component';
import { FileSizePipe } from '@desquare/components/common/src/pipe/file-size/file-size.pipe';
import { DateProxyPipe } from '@desquare/components/common/src/pipe/pipe/date-proxy.pipe';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [
    CopyToClipboardComponent,
    TranslateModule,
    FileSizePipe,
    DateProxyPipe,
  ],
  selector: 'app-media-meta-data',
  template: `
    <table class="table table-sm" style="table-layout: fixed;">
      <tr>
        <td style="width: 8rem">{{ 'SECURE_URL' | translate }}</td>
        <td class="d-flex w-100">
          <span class="text-truncate">
            {{ details().secureUrl || ('NO_DATA' | translate) }}
          </span>
          <copy-to-clipboard
            [cbcontent]="details().secureUrl"
          ></copy-to-clipboard>
        </td>
      </tr>
      <tr>
        <td>{{ 'RESOURCE_TYPE' | translate }}</td>
        <td>{{ details().resourceType || ('NO_DATA' | translate) }}</td>
      </tr>
      <tr>
        <td>{{ 'FORMAT' | translate }}</td>
        <td>{{ details().format || ('NO_DATA' | translate) }}</td>
      </tr>
      <tr>
        <td>{{ 'FILE_SIZE' | translate }}</td>
        <td>{{ (details().bytes | fileSize) || ('NO_DATA' | translate) }}</td>
      </tr>
      <br />
      <tr>
        <td>{{ 'WIDTH' | translate }}</td>
        <td>{{ details().width || ('NO_DATA' | translate) }} px</td>
      </tr>
      <tr>
        <td>{{ 'HEIGHT' | translate }}</td>
        <td>{{ details().height || ('NO_DATA' | translate) }} px</td>
      </tr>
      <tr>
        <td>{{ 'ASPECT_RATIO' | translate }}</td>
        <td>{{ details().aspectRatio || ('NO_DATA' | translate) }}</td>
      </tr>
      @if(details().duration){
      <br />
      <tr>
        <td>{{ 'DURATION' | translate }}</td>
        <td>
          {{ this.details().duration * 1000 | date : 'mm:ss' }}
        </td>
      </tr>
      }
      <br />
      <tr>
        <td>{{ 'CREATED_AT' | translate }}</td>
        <td>
          {{
            (details().createdAt | date : 'short') || ('NO_DATA' | translate)
          }}
        </td>
      </tr>
    </table>
  `,
  styles: [``],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaMetaDataComponent {
  ResourceType = ResourceType;
  details = input.required<MediaMetadata>();
  formattedDuration = computed(() => this.details().duration);
}
