import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  input,
  computed,
  effect,
} from '@angular/core';
import {
  Maybe,
  UpdateMediaGQL,
  DeleteMediaGQL,
  Media,
  MediaVisibilityType,
  MoveMediaFolderGQL,
} from '@designage/gql';
import { SubSink } from 'subsink';
import {
  FormGroup,
  FormBuilder,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  ToasterService,
  RoutingStateService,
  EncryptionService,
  CurrentUserService,
  FolderService,
  MediaService,
} from '@desquare/services';
import { CeCalledFrom, MediaSourceTypes } from '@desquare/enums';
import { NgbModal, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { CreativeEditorComponent } from '@designage/app/creative-editor/creative-editor/creative-editor.component';
import { MoveMediaFolderDialogComponent } from '@desquare/components/common/src/modals/move-media-folder-dialog.component';
import { lastValueFrom } from 'rxjs';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { FormDialogComponent } from '@desquare/components/common/src/modals/form-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderComponent } from '@desquare/components/common/src/loader/loader.component';
import { Cloudinary, CloudinaryFile } from '@cloudinary/url-gen';

interface IUpdateMediaForm {
  name: string;
}

@Component({
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TranslateModule,
    FormDialogComponent,
    NgbTooltip,
    MatButtonModule,
    MatMenuModule,
    LoaderComponent,
  ],
  selector: 'app-media-settings-form',
  templateUrl: './media-settings-form.component.html',
  styleUrls: ['./media-settings-form.component.scss'],
})
export class MediaSettingsFormComponent implements OnInit {
  private subs = new SubSink();

  media = input.required<Media>();
  downloadLink = input<string>();
  @Output() updatedMedia = new EventEmitter<Media>();
  @Output() mediaDeleted = new EventEmitter();

  mediaForm!: FormGroup;
  loaderMessage!: string;
  loading!: boolean;
  backRoute!: string;
  downloadLinkAttachment = computed(() =>
    this.downloadLink()?.replace('upload/', 'upload/fl_attachment/')
  );
  downloadLinkBeautyName = computed(() => {
    if (this.downloadLinkAttachment()) {
      const path = this.downloadLinkAttachment()!.substring(
        0,
        this.downloadLinkAttachment()!.lastIndexOf('/') + 1
      );
      const file = path
        .replace('upload/', 'upload/fl_attachment/')
        .concat(this.media().name)
        .concat(this.media().metadata?.format!);

      return file;
    }
    return '';
  });

  canEdit = false;

  constructor(
    private toasterService: ToasterService,
    private updateMediaGQL: UpdateMediaGQL,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private routingStateService: RoutingStateService,
    private router: Router,
    private encryptionService: EncryptionService,
    private currentUserService: CurrentUserService,
    private mediaService: MediaService
  ) {}

  ngOnInit() {
    this.initForm();
    this.setControlValues(this.media());
  }

  setControlValues(media: Maybe<Media>) {
    if (media) {
      this.mediaForm.patchValue({
        name: media.name,
      });
    }
  }

  initForm() {
    this.mediaForm = this.formBuilder.group({
      name: [null, Validators.required],
    });

    if (this.media()) {
      if (this.media().visibility === MediaVisibilityType.Template) {
        this.canEdit = this.currentUserService.canEditTemplate;
      } else {
        this.canEdit = this.currentUserService.canEditMedia;
      }
    }
    this.backRoute = this.routingStateService.getPreviousUrl();
  }

  onSubmit(form: IUpdateMediaForm) {
    this.loading = true;
    this.loaderMessage = 'UPDATING_MEDIA';
    if (this.media() && this.media().id) {
      this.subs.sink = this.updateMediaGQL
        .mutate({
          input: {
            id: this.media().id,
            name: form.name,
          },
        })
        .subscribe({
          next: ({ data }) => {
            if (data && data.updateMedia && data.updateMedia.isSuccessful) {
              // this.media() = data.updateMedia.media as Media | null;
              this.toasterService.success('UPDATE_MEDIA_SUCCESSFUL');
              this.updatedMedia.emit(data.updateMedia.media as Media);

              // refetch media list
              this.mediaService.refetchMedia();
            } else {
              this.toasterService.error('UPDATE_MEDIA_ERROR');
            }
            this.loading = false;
          },
          error: () => {
            this.toasterService.error('UPDATE_MEDIA_ERROR');
          },
        });
    } else {
      this.toasterService.error('COULD_NOT_FIND_MEDIA_TO_UPDATE');
    }
  }

  async deleteMediaDialog() {
    const folderId = this.media().folderId ?? null;
    await this.mediaService.openDeleteMediaDialog({
      mediaList: [this.media()],
      mediaFolderId: folderId,
    });
    this.mediaDeleted.emit();
  }

  async openDeleteMediaDialog() {
    if (this.media().folderId === undefined) console.error('no media.folderId');

    if (
      this.media() &&
      this.media().folderId !== undefined &&
      this.media().folderId !== null
    ) {
      this.loading = true;
      this.loaderMessage = 'DELETING_MEDIA';
      await this.mediaService.openDeleteMediaDialog({
        mediaList: [this.media()],
        mediaFolderId: this.media().folderId as string,
      });
      this.loading = false;

      this.navigateBack();
    } else {
      this.toasterService.error('COULD_NOT_FIND_MEDIA_TO_DELETE');
    }
  }

  navigateBack() {
    this.router.navigateByUrl(this.routingStateService.getPreviousUrl());
  }

  editMedia() {
    if (this.media()) {
      const id = this.encryptionService.encrypt(this.media().id);

      if (this.canEdit) {
        // this.router.navigate(['/media/edit', id]);
        const modalRef = this.modalService.open(CreativeEditorComponent, {
          backdrop: 'static',
          windowClass: 'cesdk-modal',
          keyboard: false,
        });
        modalRef.componentInstance.calledFrom = CeCalledFrom.MEDIALIST;
        modalRef.componentInstance.mediaId = this.media().id;
        console.log('type:', this.media().type);
        if (this.media().type === 'IMAGE') {
          modalRef.componentInstance.initialSceneMode = 'Design';
          modalRef.componentInstance.inputImgUrl = this.media().secureUrl;
        }
        if (this.media().type === 'VIDEO') {
          modalRef.componentInstance.initialSceneMode = 'Video';
          modalRef.componentInstance.inputVideoUrl = this.media().secureUrl;
        }

        // refetch media list after closing the creative editor
        this.subs.sink = modalRef.closed.subscribe(() => {
          this.mediaService.refetchMedia();
        });
      }
    }
  }

  moveMediaFolder() {
    const media = this.media();
    if (!media) return;

    const modal = this.modalService.open(MoveMediaFolderDialogComponent, {
      backdrop: 'static',
      size: 'lg',
    });

    modal.componentInstance.selectedMedia = [media.name];

    modal.result.then(
      (folderId: string | null) => {
        lastValueFrom(
          this.mediaService.moveMediaToFolder({
            folderId,
            mediaIds: [media.id],
          })
        );
        // this.subs.sink = this.moveMediaFolderGQL
        //   .mutate({ input: { folderId, mediaIds: [media.id] } })
        //   .subscribe((result) => {
        //     if (result.data?.moveMediaFolder?.isSuccessful) {
        //       // this.datatableLoading = false;
        //       this.toasterService.success('MOVE_MEDIA_FOLDER_SUCCESS');

        //       // refresh media list
        //       // this.refetchProfileMediaQuery();
        //       // TODO: reset selected medias
        //     }
        //   });
      },
      () => {}
    );
  }
}
