<form>
  <div class="p-2 position-relative">
    @if(isEditingSettings() || saving()){
    <div class="setting-buttons" [@editSettingsControls]>
      <div class="commit-form-buttons">
        <span class="badge rounded-pill text-bg-warning text-white">
          <i
            class="ti px-2 ti-alert"
            ngbTooltip="{{ 'SAVE_SETTING_WARNING' | translate }}"
          ></i>
        </span>
        <div class="btn btn-group me-2" style="margin-top: -5px">
          <button class="btn btn-sm btn-danger" (click)="onCancel()">
            <i class="ti ti-close"></i>
          </button>
          <!-- TODO Save should be disabled till form is dirty-->
          <button class="btn btn-sm btn-success" (click)="onSubmit()">
            @if(saving()){
            <ng-container [ngTemplateOutlet]="loadingSpinner" />
            } @else {
            <i class="ti ti-check"></i>
            }
          </button>
        </div>
      </div>
    </div>
    }@else{
    <button
      class="btn btn-sm btn-primary edit-settings-toggle"
      (click)="onEditSettings()"
      [hidden]="isEditingSettings()"
    >
      {{ 'SETTINGS_EDIT' | translate }}
    </button>
    }
  </div>
  <div class="p-2">
    <table class="table table-sm mt-4">
      <tbody>
        <!-- MARK: Name and channel -->
        <tr>
          <td>
            {{ 'NAME' | translate }}
          </td>
          @if(!isEditingSettings()){
          <td>
            {{ device().name || ('NO_DATA' | translate) }}
          </td>
          } @else {
          <td>
            <input
              class="form-control form-control-dark form-control-sm"
              type="text"
              [ngModel]="viewModel().deviceSettings.name"
              (ngModelChange)="onNameChange($event)"
              name="name"
            />
          </td>
          }
        </tr>
        <tr>
          <td>
            {{ 'CHANNEL' | translate }}
          </td>
          @if(!isEditingSettings() || !isUserSuperAdmin){
          <td>
            {{ device().channel?.name }}
          </td>
          } @else {
          <td>
            <div ngbDropdown container="body">
              <input
                (click)="$event.stopPropagation()"
                type="button"
                class="form-control form-control-dark dropdown"
                ngbDropdownToggle
                [value]="viewModel().deviceSettings.channel?.name"
              />
              <div
                ngbDropdownMenu
                class="form-control form-control-dark shadowed-box text-white"
              >
                @for(channel of profileChannels() | orderBy: 'name'; track
                channel.id){
                <span
                  ngbDropdownItem
                  class="form-control form-control-dark dropdown-items"
                  (click)="onChannelChange(channel)"
                  >{{ channel.name }}</span
                >
                }
              </div>
            </div>
          </td>
          }
        </tr>
      </tbody>
      <tbody>
        <!-- MARK: Time Settings -->
        <tr>
          <td
            colspan="2"
            class="accordion-custom p-0 mt-2"
            ngbAccordion
            [destroyOnHide]="false"
          >
            <div ngbAccordionItem>
              <h4 ngbAccordionHeader class="mt-0">
                <button class="fs-4 fw-bold" ngbAccordionButton>
                  {{ 'TIME' | translate }}
                </button>
              </h4>
              <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                  <ng-template>
                    <table class="table table-sm">
                      <tbody>
                        <tr>
                          <td>
                            {{ 'LOCAL_DEVICE_TIME' | translate }},
                            {{ 'DATE' | translate }}
                          </td>
                          <td [class]="isEditingSettings() ? 'px-3' : ''">
                            {{
                              (device().deviceInfo?.currentTime?.currentDate
                                | date
                                  : 'shortTime'
                                  : undefined
                                  : device().timezoneOffset) ||
                                ('NO_DATA' | translate)
                            }},
                            {{
                              (device().deviceInfo?.currentTime?.currentDate
                                | date
                                  : 'mediumDate'
                                  : undefined
                                  : device().timezoneOffset) ||
                                ('NO_DATA' | translate)
                            }}
                          </td>
                        </tr>
                        <tr>
                          @if(!isEditingSettings()){
                          <td>
                            {{ 'TIME_ZONE' | translate }}
                          </td>
                          <td>
                            {{
                              device().deviceInfo?.currentTime?.timezone ||
                                ('NO_DATA' | translate)
                            }}
                          </td>
                          } @else {
                          <td>
                            <button
                              class="btn btn-sm btn-outline-primary"
                              (click)="getProfileTimezone()"
                              [ngbTooltip]="
                                profileSettingsService.getProfileTimezoneValue()
                                  .timezone
                              "
                            >
                              {{ 'USE_PROFILE_TIMEZONE' | translate }}
                            </button>
                          </td>
                          <td class="w-100 d-flex align-items-center">
                            <designage-typeahead
                              #timezoneTypeahead
                              class="flex-grow-1 position-relative"
                              [formSizeSmall]="true"
                              [items]="timezones"
                              [defaultItem]="
                                viewModel().deviceSettings.deviceInfo
                                  ?.currentTime?.timezone ?? ''
                              "
                              [placeHolderText]="'SELECT_TIMEZONE' | translate"
                              (selectItem)="updateTimezone($event)"
                              [inputDisabled]="!isDeviceOnline()"
                            ></designage-typeahead>
                          </td>
                          }
                        </tr>
                        <tr>
                          <td>
                            {{ 'NTP_SERVER' | translate }}
                          </td>
                          @if(!isEditingSettings()){
                          <td>
                            {{
                              device().deviceInfo?.currentTime?.ntpServer ||
                                ('NO_DATA' | translate)
                            }}
                          </td>
                          } @else {
                          <td>
                            <input
                              class="form-control form-control-dark form-control-sm"
                              type="text"
                              [ngModel]="
                                viewModel().deviceSettings.deviceInfo
                                  ?.currentTime?.ntpServer
                              "
                              (ngModelChange)="onNtpServerChange($event)"
                              name="ntpServer"
                              [disabled]="!isDeviceOnline()"
                            />
                          </td>
                          }
                        </tr>
                      </tbody>
                    </table>
                  </ng-template>
                </div>
              </div>
            </div>
          </td>
        </tr>

        <!-- MARK: System Settings -->
        <tr>
          <td
            colspan="2"
            class="accordion-custom p-0 mt-2"
            ngbAccordion
            [destroyOnHide]="false"
          >
            <div ngbAccordionItem>
              <h4 ngbAccordionHeader class="mt-0">
                <button class="fs-4 fw-bold" ngbAccordionButton>
                  {{ 'SETTINGS_SYSTEM_HEADER' | translate }}
                </button>
              </h4>
              <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                  <ng-template>
                    <table class="table table-sm">
                      <tbody>
                        @if(!isEditingSettings()){
                        <tr
                          style="height: 2.1rem"
                          class="align-middle border-top mt-2"
                        >
                          <td class="position-relative">
                            <span
                              ngbTooltip="{{
                                'REBOOT_SCHEDULE_TOOLTIP' | translate
                              }}"
                              >{{ 'REBOOT_SCHEDULE' | translate }}</span
                            >
                          </td>
                          <td class="d-flex align-items-baseline">
                            <div
                              class="badge"
                              [class.bg-success]="
                                device().advancedSettings?.scheduleDailyReboot
                              "
                              [class.bg-secondary]="
                                !device().advancedSettings?.scheduleDailyReboot
                              "
                            >
                              {{
                                (device().advancedSettings?.scheduleDailyReboot
                                  ? (device().advancedSettings?.rebootTime ?? ''
                                    | slice : 0 : 5)
                                  : 'OFF'
                                ) | translate
                              }}
                            </div>
                          </td>
                        </tr>
                        }@else {
                        <tr
                          style="height: 2.1rem"
                          class="align-middle border-top mt-2"
                        >
                          <td class="position-relative">
                            <span
                              ngbTooltip="{{
                                'REBOOT_SCHEDULE_TOOLTIP' | translate
                              }}"
                              >{{ 'REBOOT_SCHEDULE' | translate }}</span
                            >
                          </td>
                          <td
                            class="d-flex align-items-baseline position-relative"
                            ngbDropdown
                            container="body"
                          >
                            <div class="ms-2 form-check form-switch">
                              <input
                                type="checkbox"
                                class="form-check-input"
                                id="toggleRebootScheduleCheckbox"
                                [checked]="
                                  viewModel().deviceSettings.advancedSettings
                                    ?.scheduleDailyReboot
                                "
                                (change)="toggleRebootSchedule()"
                              />
                              <label
                                class="form-check-label"
                                for="toggleRebootScheduleCheckbox"
                              ></label>
                            </div>
                            @if(viewModel().deviceSettings.advancedSettings?.scheduleDailyReboot){
                            <span
                              class="cursor-pointer badge text-bg-dark"
                              ngbDropdownToggle
                            >
                              <i
                                [class.bg-success]="
                                  viewModel().deviceSettings.advancedSettings
                                    ?.scheduleDailyReboot
                                "
                                [class.bg-secondary]="
                                  !viewModel().deviceSettings.advancedSettings
                                    ?.scheduleDailyReboot
                                "
                                class="badge-icon ri-restart-line"
                              ></i>
                              {{
                                (viewModel().deviceSettings.advancedSettings
                                  ?.scheduleDailyReboot != null &&
                                viewModel().deviceSettings.advancedSettings
                                  ?.scheduleDailyReboot === true
                                  ? (viewModel().deviceSettings.advancedSettings
                                      ?.rebootTime ?? '' | slice : 0 : 5)
                                  : 'OFF'
                                ) | translate
                              }}
                            </span>

                            <div class="time-picker" ngbDropdownMenu>
                              <ngb-timepicker
                                size="small"
                                [ngModel]="
                                  viewModel().deviceSettings.advancedSettings
                                    ?.rebootTime
                                "
                                name="rebootTime"
                                (ngModelChange)="onRebootTimeChange($event)"
                              ></ngb-timepicker>
                            </div>

                            }@else {
                            <div
                              class="badge"
                              [class.bg-success]="
                                viewModel().deviceSettings.advancedSettings
                                  ?.scheduleDailyReboot
                              "
                              [class.bg-secondary]="
                                !viewModel().deviceSettings.advancedSettings
                                  ?.scheduleDailyReboot
                              "
                            >
                              {{
                                (viewModel().deviceSettings.advancedSettings
                                  ?.scheduleDailyReboot
                                  ? viewModel().deviceSettings.advancedSettings
                                      ?.rebootTime ?? ''
                                  : 'OFF'
                                ) | translate
                              }}
                            </div>
                            }
                          </td>
                        </tr>
                        }
                        <tr
                          style="height: 2.1rem"
                          class="align-middle border-top mt-2"
                        >
                          <td class="position-relative">
                            <span
                              ngbTooltip="{{
                                'DEVICE_KIOSK_MODE_TT' | translate
                              }}"
                            >
                              {{ 'DEVICE_KIOSK_MODE' | translate }}
                            </span>
                          </td>
                          <td class="d-flex align-items-baseline">
                            @if(!isEditingSettings()){

                            <div
                              class="badge"
                              [class.bg-success]="
                                device().advancedSettings?.kioskMode
                              "
                              [class.bg-secondary]="
                                !device().advancedSettings?.kioskMode
                              "
                            >
                              {{
                                (device().advancedSettings?.kioskMode
                                  ? 'ON'
                                  : 'OFF'
                                ) | translate
                              }}
                            </div>
                            } @else {
                            <div class="ms-2 form-check form-switch">
                              <input
                                type="checkbox"
                                class="form-check-input"
                                id="toggleKioskModeCheckbox"
                                [checked]="
                                  viewModel().deviceSettings.advancedSettings
                                    ?.kioskMode
                                "
                                (change)="onKioskModeChange()"
                              />
                              <label
                                class="form-check-label"
                                for="toggleKioskModeCheckbox"
                              ></label>
                            </div>
                            <div
                              class="badge"
                              [class.bg-success]="
                                viewModel().deviceSettings.advancedSettings
                                  ?.kioskMode
                              "
                              [class.bg-secondary]="
                                !viewModel().deviceSettings.advancedSettings
                                  ?.kioskMode
                              "
                            >
                              {{
                                (viewModel().deviceSettings.advancedSettings
                                  ?.kioskMode
                                  ? 'ON'
                                  : 'OFF'
                                ) | translate
                              }}
                            </div>
                            }
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </ng-template>
                </div>
              </div>
            </div>
          </td>
        </tr>

        <!-- MARK: Screen settings and resolution -->
        <tr>
          <td
            colspan="2"
            class="accordion-custom p-0 mt-2"
            ngbAccordion
            [destroyOnHide]="false"
          >
            <div ngbAccordionItem>
              <h4 ngbAccordionHeader class="mt-0">
                <button
                  class="fs-4 fw-bold"
                  ngbAccordionButton
                  ngbTooltip="{{ 'SCREEN_RESOLUTION_TOOLTIP' | translate }}"
                >
                  {{ 'SCREEN_SETTINGS' | translate }}
                </button>
              </h4>
              <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                  <ng-template>
                    <!-- MARK: Screen Orientation and Resolution -->
                    <table class="table table-sm">
                      <tbody>
                        <tr>
                          <td>
                            {{ 'SCREEN_ORIENTATION' | translate }}
                          </td>
                          @if(!isEditingSettings()){
                          <td>
                            {{
                              device().deviceInfo?.screen?.orientation
                                | titlecase
                            }}
                          </td>
                          } @else {
                          <td>
                            <div ngbDropdown container="body">
                              <input
                                (click)="$event.stopPropagation()"
                                type="button"
                                class="form-control form-control-dark dropdown"
                                ngbDropdownToggle
                                [value]="
                                  viewModel().deviceSettings.advancedSettings
                                    ?.orientation
                                "
                              />
                              <div
                                ngbDropdownMenu
                                class="form-control form-control-dark shadowed-box text-white"
                              >
                                @for(orientation of orientations; track
                                orientation){
                                <span
                                  ngbDropdownItem
                                  class="form-control form-control-dark dropdown-items"
                                  (click)="onOrientationChange(orientation)"
                                  >{{ orientation }}</span
                                >
                                }
                              </div>
                            </div>
                          </td>
                          }
                        </tr>
                        <tr>
                          <td
                            ngbTooltip="{{ 'FLIP_SCREENSHOT_TT' | translate }}"
                          >
                            {{ 'FLIP_SCREENSHOT' | translate }}
                          </td>
                          @if(!isEditingSettings()){
                          <td>
                            <div
                              class="badge"
                              [class.bg-success]="
                                device().advancedSettings?.flipScreenshot
                              "
                              [class.bg-secondary]="
                                !device().advancedSettings?.flipScreenshot
                              "
                            >
                              {{
                                (device().advancedSettings?.flipScreenshot
                                  ? 'ON'
                                  : 'OFF'
                                ) | translate
                              }}
                            </div>
                          </td>

                          } @else {
                          <td class="d-flex align-items-center">
                            <div class="ms-2 form-check form-switch">
                              <input
                                type="checkbox"
                                class="form-check-input"
                                id="toggleKioskModeCheckbox"
                                [checked]="
                                  viewModel().deviceSettings.advancedSettings
                                    ?.flipScreenshot
                                "
                                (change)="onFlipScreenshotChange()"
                              />
                              <label
                                class="form-check-label"
                                for="toggleKioskModeCheckbox"
                              ></label>
                            </div>
                            <div
                              class="badge"
                              [class.bg-success]="
                                viewModel().deviceSettings.advancedSettings
                                  ?.flipScreenshot
                              "
                              [class.bg-secondary]="
                                !viewModel().deviceSettings.advancedSettings
                                  ?.flipScreenshot
                              "
                            >
                              {{
                                (viewModel().deviceSettings.advancedSettings
                                  ?.flipScreenshot
                                  ? 'ON'
                                  : 'OFF'
                                ) | translate
                              }}
                            </div>
                          </td>
                          }
                        </tr>
                        <tr>
                          <td>
                            {{ 'SCREEN_RESOLUTION_WIDTH' | translate }}
                          </td>
                          <td>
                            {{ device().width || ('NO_DATA' | translate) }}
                            pixels
                          </td>
                          <!-- 
                            @if(!isEditingSettings()){
                            } @else {
                          <td>
                            <input
                              class="form-control form-control-dark form-control-sm"
                              type="text"
                              [ngModel]="viewModel().deviceSettings.width"
                              name="width"
                              [disabled]="!isDeviceOnline()"
                            />
                          </td>
                          } -->
                        </tr>
                        <tr>
                          <td>
                            {{ 'SCREEN_RESOLUTION_HEIGHT' | translate }}
                          </td>
                          <td>
                            {{ device().height || ('NO_DATA' | translate) }}
                            pixels
                          </td>
                          <!-- @if(!isEditingSettings()){
                          } @else {
                          <td>
                            <input
                              class="form-control form-control-dark form-control-sm"
                              type="text"
                              [ngModel]="viewModel().deviceSettings.height"
                              name="height"
                              [disabled]="!isDeviceOnline()"
                            />
                          </td>
                          } -->
                        </tr>
                        @if(isEditingSettings()){
                        <tr>
                          <td colspan="2">
                            <p class="mb-0 my-1 text-light fst-italic fs-5">
                              Resoultion is driven by the device and cannot be
                              changed. Contact support&#64;designage.io for more
                              information on this.
                            </p>
                            <p class="mb-0 my-1 text-light fst-italic fs-6">
                              Stay tuned for updates on this feature.
                            </p>
                          </td>
                        </tr>
                        }
                      </tbody>
                    </table>

                    <hr />
                    <!-- MARK: Volume Slider -->
                    <table class="table table-sm">
                      <tbody>
                        @if(!isEditingSettings()){
                        <tr class="align-middle">
                          <td class="position-relative">
                            {{ 'DEVICE_VOLUME' | translate }}
                          </td>
                          <td class="custom-slider">
                            <app-device-volume
                              [deviceInfo]="device().deviceInfo"
                              [status]="device().status"
                            />
                          </td>
                        </tr>
                        } @else {

                        <tr class="align-middle">
                          <td
                            ngbTooltip="{{
                              'DEVICE_VOLUME_SET_TT' | translate
                            }}"
                            class="position-relative"
                          >
                            {{ 'DEVICE_VOLUME_SET' | translate }}
                            <div
                              class="btn-group btn-group-sm slider-confirm-cancel-button volume"
                            ></div>
                          </td>
                          <td class="custom-slider">
                            <ngx-slider
                              [value]="
                                viewModel().deviceSettings.deviceInfo?.screen
                                  ?.volume ?? 0
                              "
                              (valueChange)="onVolumeChange($event)"
                              [options]="{
                                floor: 0,
                                ceil: 100,
                              }"
                            ></ngx-slider>
                          </td>
                        </tr>
                        }
                      </tbody>
                    </table>

                    <hr />
                    <!-- MARK: Display Brightness -->
                    <table class="table table-sm">
                      <tbody>
                        <!-- Set standard brightness -->
                        @if(!isEditingSettings()){
                        @if(device().advancedSettings?.scheduleBrightness?.brightness1
                        ===
                        device().advancedSettings?.scheduleBrightness?.brightness2){
                        <tr>
                          <td class="align-middle">
                            <span>
                              {{ 'DEVICE_BRIGHTNESS' | translate }}
                            </span>
                          </td>
                          <td class="align-middle custom-slider">
                            <app-device-brightness
                              [deviceInfo]="device().deviceInfo"
                              [status]="device().status"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                        </tr>
                        } @else {
                        <tr>
                          <td class="align-middle">
                            <div class="d-flex align-items-center">
                              <span
                                class="brightness-badge-width"
                                ngbTooltip="{{
                                  'DEVICE_BRIGHTNESS_DAY_TT' | translate
                                }}"
                              >
                                {{ 'DEVICE_BRIGHTNESS_DAY' | translate }}
                              </span>
                            </div>
                          </td>
                          <td
                            class="d-flex align-items-center w-100 custom-slider"
                          >
                            <!-- Back to standard brightness -->
                            <div ngbDropdown container="body">
                              <span class="badge text-bg-dark me-4">
                                <i
                                  class="badge-icon text-bg-light ri-arrow-up-fill"
                                ></i>
                                {{
                                  device().advancedSettings?.scheduleBrightness
                                    ?.time1 | slice : 0 : 5
                                }}
                              </span>
                            </div>
                            <app-device-brightness
                              [deviceInfo]="device().deviceInfo"
                              [status]="device().status"
                              [value]="
                                device().advancedSettings?.scheduleBrightness
                                  ?.brightness1
                              "
                            />
                          </td>
                        </tr>

                        <!-- Set Dim down brightness and schedule -->
                        <tr>
                          <td class="align-middle">
                            <div class="d-flex align-items-center">
                              <span
                                class="brightness-badge-width"
                                ngbTooltip="{{
                                  'DEVICE_BRIGHTNESS_NIGHT_TT' | translate
                                }}"
                              >
                                {{ 'DEVICE_BRIGHTNESS_NIGHT' | translate }}
                              </span>
                              <!-- Back to standard brightness -->
                            </div>
                          </td>
                          <td
                            class="d-flex align-items-center w-100 custom-slider"
                          >
                            <div ngbDropdown container="body">
                              <span class="badge text-bg-dark me-4">
                                <i
                                  class="badge-icon text-bg-secondary ri-arrow-down-line"
                                ></i>
                                {{
                                  device().advancedSettings?.scheduleBrightness
                                    ?.time2 | slice : 0 : 5
                                }}
                              </span>
                            </div>
                            <app-device-brightness
                              [deviceInfo]="device().deviceInfo"
                              [status]="device().status"
                              [value]="
                                device().advancedSettings?.scheduleBrightness
                                  ?.brightness2
                              "
                            />
                          </td>
                        </tr>
                        } }@else {
                        @if(viewModel().deviceSettings.advancedSettings?.scheduleBrightness?.brightness1
                        ===
                        viewModel().deviceSettings.advancedSettings?.scheduleBrightness?.brightness2){
                        <tr>
                          <td
                            class="align-middle"
                            ngbTooltip="{{
                              'DEVICE_BRIGHTNESS_SET_TT' | translate
                            }}"
                          >
                            {{ 'DEVICE_BRIGHTNESS_SET' | translate }}
                          </td>
                          <td>
                            <div class="d-flex align-items-baseline">
                              <div
                                class="text-nowrap badge me-2"
                                [ngClass]="
                                  viewModel().deviceSettings.advancedSettings
                                    ?.scheduleBrightness?.brightness1 ===
                                  viewModel().deviceSettings.advancedSettings
                                    ?.scheduleBrightness?.brightness2
                                    ? 'bg-secondary'
                                    : 'bg-success'
                                "
                              >
                                {{ 'SCHEDULE' | translate }}
                              </div>
                              <span
                                class="form-check form-switch"
                                ngbTooltip="{{
                                  'DEVICE_BRIGHTNESS_TT' | translate
                                }}"
                              >
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  id="toggleDeviceScreenDimCheckbox"
                                  name="toggleDeviceScreenDimCheckbox"
                                  [checked]="
                                    viewModel().deviceSettings.advancedSettings
                                      ?.scheduleBrightness?.brightness1 !==
                                    viewModel().deviceSettings.advancedSettings
                                      ?.scheduleBrightness?.brightness2
                                  "
                                  (click)="toggleBrightnessSchedule($event)"
                                />
                                <label
                                  class="form-check-label"
                                  for="toggleDeviceScreenDimCheckbox"
                                ></label>
                              </span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td></td>
                          <td class="align-middle custom-slider">
                            <ngx-slider
                              [value]="
                                viewModel().deviceSettings.advancedSettings
                                  ?.scheduleBrightness?.brightness1 ?? 100
                              "
                              [options]="sliderOptions"
                              (valueChange)="
                                onBrightnessChange('common', $event)
                              "
                            ></ngx-slider>
                          </td>
                        </tr>

                        } @else {
                        <tr>
                          <td
                            class="align-middle"
                            ngbTooltip="{{
                              'DEVICE_BRIGHTNESS_SET_TT' | translate
                            }}"
                          >
                            {{ 'DEVICE_BRIGHTNESS_SET' | translate }}
                          </td>
                          <td>
                            <div class="d-flex align-items-baseline">
                              <div
                                class="text-nowrap badge me-2"
                                [ngClass]="
                                  viewModel().deviceSettings.advancedSettings
                                    ?.scheduleBrightness?.brightness1 ===
                                  viewModel().deviceSettings.advancedSettings
                                    ?.scheduleBrightness?.brightness2
                                    ? 'bg-secondary'
                                    : 'bg-success'
                                "
                              >
                                {{ 'SCHEDULE' | translate }}
                              </div>
                              <span
                                class="form-check form-switch"
                                ngbTooltip="{{
                                  'DEVICE_BRIGHTNESS_TT' | translate
                                }}"
                              >
                                <input
                                  type="checkbox"
                                  class="form-check-input"
                                  id="toggleDeviceScreenDimCheckbox"
                                  name="toggleDeviceScreenDimCheckbox"
                                  [checked]="
                                    viewModel().deviceSettings.advancedSettings
                                      ?.scheduleBrightness?.brightness1 !==
                                    viewModel().deviceSettings.advancedSettings
                                      ?.scheduleBrightness?.brightness2
                                  "
                                  (click)="toggleBrightnessSchedule($event)"
                                />
                                <label
                                  class="form-check-label"
                                  for="toggleDeviceScreenDimCheckbox"
                                ></label>
                              </span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td class="align-middle">
                            <div class="d-flex align-items-center">
                              <span
                                class="flex-grow-1"
                                ngbTooltip="{{
                                  'DEVICE_BRIGHTNESS_DAY_TT' | translate
                                }}"
                              >
                                {{ 'DEVICE_BRIGHTNESS_DAY' | translate }}
                                {{
                                  viewModel().deviceSettings?.scheduleBrightness
                                    ?.time1
                                }}
                              </span>
                            </div>
                          </td>
                          <td
                            class="d-flex align-items-center w-100 custom-slider"
                          >
                            <div ngbDropdown container="body">
                              <span
                                class="cursor-pointer badge text-bg-dark me-2"
                                ngbDropdownToggle
                              >
                                <i
                                  class="badge-icon text-bg-light ri-arrow-up-fill"
                                ></i>
                                {{
                                  viewModel().deviceSettings.advancedSettings
                                    ?.scheduleBrightness?.time1 | slice : 0 : 5
                                }}
                              </span>
                              <div class="time-picker" ngbDropdownMenu>
                                <ngb-timepicker
                                  size="small"
                                  [ngModel]="
                                    viewModel().deviceSettings.advancedSettings
                                      ?.scheduleBrightness?.time1
                                  "
                                  (ngModelChange)="
                                    onBrightnessTimeChange('time1', $event)
                                  "
                                  name="brightnessTimeFrom1"
                                ></ngb-timepicker>
                              </div>
                            </div>
                            <ngx-slider
                              [value]="
                                viewModel().deviceSettings.advancedSettings
                                  ?.scheduleBrightness?.brightness1 ?? 100
                              "
                              (valueChange)="
                                onBrightnessChange('brightness1', $event)
                              "
                              [options]="sliderOptions"
                            ></ngx-slider>
                          </td>
                        </tr>

                        <!-- Set Dim down brightness and schedule -->
                        <tr>
                          <td class="align-middle">
                            <div class="d-flex align-items-center">
                              <span
                                class="flex-grow-1"
                                ngbTooltip="{{
                                  'DEVICE_BRIGHTNESS_NIGHT_TT' | translate
                                }}"
                              >
                                {{ 'DEVICE_BRIGHTNESS_NIGHT' | translate }}
                              </span>
                            </div>
                          </td>
                          <td
                            class="d-flex align-items-center w-100 custom-slider"
                          >
                            <div ngbDropdown container="body">
                              <span
                                class="cursor-pointer badge text-bg-dark me-2"
                                ngbDropdownToggle
                              >
                                <i
                                  class="badge-icon text-bg-secondary ri-arrow-down-line"
                                ></i>
                                {{
                                  viewModel().deviceSettings.advancedSettings
                                    ?.scheduleBrightness?.time2 | slice : 0 : 5
                                }}
                              </span>
                              <div class="time-picker" ngbDropdownMenu>
                                <ngb-timepicker
                                  size="small"
                                  [ngModel]="
                                    viewModel().deviceSettings.advancedSettings
                                      ?.scheduleBrightness?.time2
                                  "
                                  (ngModelChange)="
                                    onBrightnessTimeChange('time2', $event)
                                  "
                                  name="brightnessTimeFrom2"
                                ></ngb-timepicker>
                              </div>
                            </div>
                            <ngx-slider
                              [value]="
                                viewModel().deviceSettings.advancedSettings
                                  ?.scheduleBrightness?.brightness2 ?? 100
                              "
                              (valueChange)="
                                onBrightnessChange('brightness2', $event)
                              "
                              [options]="sliderOptions"
                            ></ngx-slider>
                          </td>
                        </tr>
                        } }
                      </tbody>
                    </table>

                    <!-- MARK: Display Schedule -->
                    <hr />
                    <table class="table table-sm">
                      <tbody>
                        @if(!isEditingSettings()){
                        <tr style="height: 2.1rem" class="align-middle">
                          <td
                            style="border-bottom: none"
                            ngbTooltip="{{
                              'DEVICE_DISPLAY_SCHEDULE_TT' | translate
                            }}"
                          >
                            {{ 'DEVICE_DISPLAY_SCHEDULE' | translate }}
                          </td>

                          @if(device().advancedSettings?.scheduleDisplay ===
                          null ||
                          device().advancedSettings?.scheduleDisplay.length ===
                          0){
                          <td
                            style="border-bottom: none"
                            class="d-flex align-items-baseline"
                          >
                            <div
                              class="badge bg-secondary"
                              ngbTooltip="{{
                                'DEVICE_DISPLAY_SCHEDULE_NOT_SET_TT' | translate
                              }}"
                            >
                              {{
                                'DEVICE_DISPLAY_SCHEDULE_NOT_SET' | translate
                              }}
                            </div>
                          </td>
                          }
                        </tr>

                        @if (device().advancedSettings?.scheduleDisplay &&
                        device().advancedSettings?.scheduleDisplay.length > 0) {
                        @for(displaySchedule of
                        device().advancedSettings?.scheduleDisplay; let s =
                        $index;track s){
                        <tr class="h-auto">
                          <td style="border: none">
                            @for(day of sortWeekdays(displaySchedule.days); let
                            i = $index;track day.key){

                            <label
                              class="day"
                              [ngStyle]="{
                                'background-color': day.value ? '#178863' : '',
                                border: day.value
                                  ? '1px solid #178863'
                                  : '1px solid #f1556c'
                              }"
                              ngbTooltip="{{ day.key | titlecase }}"
                            >
                              {{ day.key | slice : 0 : 1 | titlecase }}
                            </label>
                            }
                          </td>
                          <td
                            style="border: none"
                            class="d-flex align-items-end"
                          >
                            <div>
                              <span
                                class="badge text-bg-dark"
                                ngbTooltip="{{
                                  'DEVICE_DISPLAY_SCHEDULE_ON' | translate
                                }}"
                              >
                                <!-- <i class="badge-icon bg-success ri-shut-down-fill"></i> -->
                                <span class="badge-icon bg-light">{{
                                  'ON' | translate
                                }}</span>
                                {{ displaySchedule.on | slice : 0 : 5 }}
                              </span>
                            </div>

                            <div>
                              <span
                                class="ms-2 badge text-bg-dark"
                                ngbTooltip="{{
                                  'DEVICE_DISPLAY_SCHEDULE_OFF' | translate
                                }}"
                              >
                                <span class="badge-icon bg-secondary">{{
                                  'OFF' | translate
                                }}</span>
                                {{ displaySchedule.off | slice : 0 : 5 }}
                              </span>
                            </div>
                          </td>
                        </tr>
                        }} } @else {
                        <tr style="height: 2.1rem" class="align-middle">
                          <td
                            style="border-bottom: none"
                            ngbTooltip="{{
                              'DEVICE_DISPLAY_SCHEDULE_TT' | translate
                            }}"
                          >
                            {{ 'DEVICE_DISPLAY_SCHEDULE' | translate }}
                          </td>
                          @if(viewModel().deviceSettings.advancedSettings?.scheduleDisplay
                          === null ||
                          viewModel().deviceSettings.advancedSettings?.scheduleDisplay?.length
                          === 0){
                          <td style="border-bottom: none">
                            <div
                              class="badge bg-secondary"
                              ngbTooltip="{{
                                'DEVICE_DISPLAY_SCHEDULE_NOT_SET_TT' | translate
                              }}"
                            >
                              {{
                                'DEVICE_DISPLAY_SCHEDULE_NOT_SET' | translate
                              }}
                            </div>
                          </td>
                          }
                        </tr>

                        @if
                        (viewModel().deviceSettings.advancedSettings?.scheduleDisplay
                        != null &&
                        viewModel().deviceSettings.advancedSettings?.scheduleDisplay.length
                        > 0) { @for(displaySchedule of
                        viewModel().deviceSettings.advancedSettings?.scheduleDisplay;
                        let s = $index;track s){
                        <tr class="h-auto">
                          <td style="border: none">
                            @if(displaySchedule.days){ @for(day of
                            sortWeekdays(displaySchedule.days); let i =
                            $index;track day.key){

                            <label
                              class="day cursor-pointer"
                              [ngStyle]="{
                                'background-color': day.value ? '#178863' : '',
                                border: day.value
                                  ? '1px solid #178863'
                                  : '1px solid #f1556c'
                              }"
                              (click)="switchDaySchedule(s, day.key)"
                              ngbTooltip="{{ day.key | titlecase }}"
                            >
                              {{ day.key | slice : 0 : 1 | titlecase }}
                            </label>
                            }}
                          </td>
                          <td
                            style="border: none"
                            class="d-flex align-items-end"
                          >
                            <div ngbDropdown container="body">
                              <span
                                class="cursor-pointer badge text-bg-dark"
                                ngbTooltip="{{
                                  'DEVICE_DISPLAY_SCHEDULE_ON' | translate
                                }}"
                                ngbDropdownToggle
                              >
                                <!-- <i class="badge-icon bg-success ri-shut-down-fill"></i> -->
                                <span class="badge-icon bg-light">{{
                                  'ON' | translate
                                }}</span>
                                {{ displaySchedule.on | slice : 0 : 5 }}
                              </span>
                              <div class="time-picker" ngbDropdownMenu>
                                <ngb-timepicker
                                  size="small"
                                  [ngModel]="
                                    viewModel().deviceSettings.advancedSettings
                                      ?.scheduleDisplay[s].on
                                  "
                                  (ngModelChange)="
                                    setDisplaySchedule('on', s, $event)
                                  "
                                  name="displayScheduleOn"
                                ></ngb-timepicker>
                              </div>
                            </div>

                            <div ngbDropdown container="body">
                              <span
                                class="cursor-pointer ms-2 badge text-bg-dark"
                                ngbTooltip="{{
                                  'DEVICE_DISPLAY_SCHEDULE_OFF' | translate
                                }}"
                                ngbDropdownToggle
                              >
                                <span class="badge-icon bg-secondary">{{
                                  'OFF' | translate
                                }}</span>
                                {{ displaySchedule.off | slice : 0 : 5 }}
                              </span>
                              <div class="time-picker" ngbDropdownMenu>
                                <ngb-timepicker
                                  size="small"
                                  [ngModel]="
                                    viewModel().deviceSettings.advancedSettings
                                      ?.scheduleDisplay[s].off
                                  "
                                  (ngModelChange)="
                                    setDisplaySchedule('off', s, $event)
                                  "
                                  name="displayScheduleOff"
                                ></ngb-timepicker>
                              </div>
                            </div>

                            <span
                              ngbTooltip="{{
                                'DEVICE_DISPLAY_SCHEDULE_REMOVE_TT' | translate
                              }}"
                              (click)="deleteDisplayScheduleRow(s)"
                              class="badge bg-danger cursor-pointer px-2"
                            >
                              <i
                                class="mx-1 cursor-pointer fs-6 text-white ri-delete-bin-5-fill"
                              ></i>
                            </span>
                          </td>
                        </tr>
                        }}
                        <tr>
                          <td></td>
                          <td class="pb-2">
                            <span
                              style="width: 13rem"
                              class="px-2 my-1 badge bg-primary cursor-pointer"
                              (click)="addDisplayScheduleRow()"
                            >
                              <i
                                class="pe-2 cursor-pointer fs-6 text-white ri-add-circle-fill"
                              ></i>
                              {{ 'DEVICE_DISPLAY_SCHEDULE_ADD' | translate }}
                            </span>
                          </td>
                        </tr>

                        }
                      </tbody>
                    </table>
                  </ng-template>
                </div>
              </div>
            </div>
          </td>
        </tr>

        <!-- MARK: Sync Settings -->
        <tr>
          <td
            colspan="2"
            class="accordion-custom p-0 mt-2"
            ngbAccordion
            [destroyOnHide]="false"
          >
            <div ngbAccordionItem>
              <h4 ngbAccordionHeader class="mt-0">
                <button
                  class="fs-4 fw-bold"
                  ngbAccordionButton
                  ngbTooltip="{{
                    'SETTINGS_SYNCHRONIZE_HEADER_TT' | translate
                  }}"
                >
                  {{ 'SETTINGS_SYNCHRONIZE_HEADER' | translate }}
                </button>
              </h4>
              <div ngbAccordionCollapse>
                <div ngbAccordionBody>
                  <ng-template>
                    <table class="table table-sm">
                      <tbody>
                        @if(!isEditingSettings()){
                        <tr>
                          <td>
                            {{ 'SETTINGS_SYNCHRONIZE' | translate }}
                          </td>
                          <td>
                            <div
                              class="badge"
                              [class.bg-success]="
                                device().advancedSettings?.synchronize
                              "
                              [class.bg-secondary]="
                                !device().advancedSettings?.synchronize
                              "
                            >
                              {{
                                (device().advancedSettings?.synchronize
                                  ? 'ON'
                                  : 'OFF'
                                ) | translate
                              }}
                            </div>
                          </td>
                        </tr>

                        @if(device().advancedSettings?.synchronize){
                        <tr>
                          <td
                            class="text-nowrap"
                            [ngbTooltip]="syncAutoGroupTooltip"
                          >
                            {{ 'SYNC_AUTO_GROUP' | translate }}
                          </td>
                          <td class="d-flex align-items-baseline">
                            <div
                              class="badge bg-primary"
                              [class.bg-success]="
                                !device().advancedSettings?.manualSyncGroup
                              "
                              [class.bg-primary]="
                                device().advancedSettings?.manualSyncGroup
                              "
                            >
                              {{
                                (device().advancedSettings?.manualSyncGroup
                                  ? 'MANUAL'
                                  : 'AUTO'
                                ) | translate
                              }}
                            </div>
                          </td>
                        </tr>

                        <tr style="height: 2rem">
                          <td>
                            {{ 'SYNC_GROUP' | translate }}
                          </td>
                          @if(!device().advancedSettings?.manualSyncGroup){
                          <td>
                            <span
                              class="text-muted text-truncate"
                              style="max-width: 12rem"
                            >
                              {{ 'AUTO' | translate }}
                            </span>
                          </td>
                          } @else {
                          <td
                            class="d-flex justify-content-between align-items-center"
                          >
                            <span
                              class="text-truncate"
                              style="max-width: 12rem"
                            >
                              {{ device().advancedSettings?.syncGroup }}
                            </span>
                            <copy-to-clipboard
                              [cbcontent]="
                                device().advancedSettings?.syncGroup || ''
                              "
                            ></copy-to-clipboard>
                          </td>
                          }
                        </tr>
                        <tr style="height: 2rem">
                          <td
                            ngbTooltip="{{
                              'SYNC_MASTER_DELAY_TT' | translate
                            }}"
                          >
                            {{ 'SYNC_MASTER_DELAY' | translate }}
                          </td>
                          <td
                            class="d-flex justify-content-start align-items-center"
                          >
                            {{ device().advancedSettings?.masterSyncDelay }} ms
                          </td>
                        </tr>
                        } } @else {

                        <tr>
                          <td>
                            {{ 'SETTINGS_SYNCHRONIZE' | translate }}
                          </td>
                          <td class="d-flex align-items-baseline">
                            <div class="me-2 form-check form-switch">
                              <input
                                type="checkbox"
                                class="form-check-input"
                                id="toggleSynchronizeCheckbox"
                                [ngModel]="
                                  viewModel().deviceSettings.advancedSettings
                                    ?.synchronize
                                "
                                name="synchronize"
                                (change)="toggleSynchronize()"
                              />
                              <label
                                [hidden]="!isEditingSettings()"
                                class="form-check-label"
                                for="toggleSynchronizeCheckbox"
                              ></label>
                            </div>
                            <div
                              class="badge"
                              [class.bg-success]="
                                viewModel().deviceSettings.advancedSettings
                                  ?.synchronize
                              "
                              [class.bg-secondary]="
                                !viewModel().deviceSettings.advancedSettings
                                  ?.synchronize
                              "
                            >
                              {{
                                (viewModel().deviceSettings.advancedSettings
                                  ?.synchronize
                                  ? 'ON'
                                  : 'OFF'
                                ) | translate
                              }}
                            </div>
                          </td>
                        </tr>

                        @if(viewModel().deviceSettings.advancedSettings?.synchronize){
                        <tr>
                          <td
                            class="text-nowrap"
                            [ngbTooltip]="syncAutoGroupTooltip"
                          >
                            {{ 'SYNC_AUTO_GROUP' | translate }}
                          </td>
                          <td class="d-flex align-items-baseline">
                            <div class="me-2 form-check form-switch">
                              <input
                                type="checkbox"
                                class="form-check-input"
                                id="toggleManualSyncGroupCheckbox"
                                name="manualSyncGroup"
                                [ngModel]="
                                  !viewModel().deviceSettings.advancedSettings
                                    ?.manualSyncGroup
                                "
                                (change)="toggleSyncAutoGroup()"
                              />
                              <label
                                [hidden]="!isEditingSettings()"
                                class="form-check-label"
                                for="toggleSynchronizeCheckbox"
                              ></label>
                            </div>
                            <div
                              class="badge"
                              [class.bg-success]="
                                !viewModel().deviceSettings.advancedSettings
                                  ?.manualSyncGroup
                              "
                              [class.bg-primary]="
                                viewModel().deviceSettings.advancedSettings
                                  ?.manualSyncGroup
                              "
                            >
                              {{
                                (viewModel().deviceSettings.advancedSettings
                                  ?.manualSyncGroup
                                  ? 'MANUAL'
                                  : 'AUTO'
                                ) | translate
                              }}
                            </div>
                          </td>
                        </tr>

                        <tr style="height: 2rem">
                          <td>
                            {{ 'SYNC_GROUP' | translate }}
                          </td>
                          @if(!viewModel().deviceSettings.advancedSettings?.manualSyncGroup){
                          <td>
                            <span
                              class="text-muted text-truncate"
                              style="max-width: 12rem"
                            >
                              {{ 'AUTO' | translate }}
                            </span>
                          </td>
                          } @else {
                          <td
                            class="d-flex justify-content-between align-items-center w-100"
                          >
                            <input
                              class="form-control form-control-dark form-control-sm"
                              [ngModel]="
                                viewModel().deviceSettings.advancedSettings
                                  ?.syncGroup
                              "
                              name="syncGroup"
                              (ngModelChange)="onSyncGroupChange($event)"
                            />
                            <copy-to-clipboard
                              [cbcontent]="
                                viewModel().deviceSettings.advancedSettings
                                  ?.syncGroup ?? ''
                              "
                            ></copy-to-clipboard>
                          </td>
                          }
                        </tr>
                        <tr style="height: 2rem">
                          <td
                            ngbTooltip="{{
                              'SYNC_MASTER_DELAY_TT' | translate
                            }}"
                          >
                            {{ 'SYNC_MASTER_DELAY' | translate }}
                          </td>
                          <td
                            class="d-flex justify-content-start align-items-center w-100"
                          >
                            <input
                              type="number"
                              step="5"
                              class="form-control form-control-dark form-control-sm"
                              style="max-width: 5rem"
                              [ngModel]="
                                viewModel().deviceSettings.advancedSettings
                                  ?.masterSyncDelay
                              "
                              (ngModelChange)="onMasterSyncDelayChange($event)"
                              name="masterSyncDelay"
                            />
                            <span>ms</span>
                          </td>
                        </tr>
                        }}
                      </tbody>
                    </table>
                  </ng-template>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</form>

<ng-template #loadingSpinner>
  <div class="spinner-border spinner-border-sm text-info" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</ng-template>

<ng-template #syncAutoGroupTooltip
  >{{ 'SYNC_AUTO_GROUP_TOOLTIP_1' | translate }}
  <tr class="mt-2"></tr>
  <tr class="mt-2"></tr>
  {{ 'SYNC_AUTO_GROUP_TOOLTIP_2' | translate }}</ng-template
>
