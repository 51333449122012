import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  computed,
  inject,
  signal,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SlidePanelComponent } from '@designage/app/shared/slide-panel/slide-panel.component';
import { TranslateModule } from '@ngx-translate/core';
import { MediaManageComponent } from '../media-manage/media-manage.component';
import { EncryptionService, SlidePanelService } from '@desquare/services';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MediaStore } from '@desquare/stores';

@Component({
  standalone: true,
  imports: [SlidePanelComponent, TranslateModule, MediaManageComponent],
  selector: 'app-media-manage-slider',
  template: `
    <app-slide-panel
      (closePanel)="closeSlidingPanel()"
      [fullscreenTooltip]="'TOGGLE_PAGE_BEHIND_PANEL_TOOLTIP' | translate"
      [closePanelTooltip]="'CLOSE_PANEL' | translate"
      [openWidth]="50"
      [fullscreen]="false"
      [panelContent]="'mediaList'"
    >
      @if(media()) {<app-media-manage
        [media]="media()"
        (mediaDeleted)="closeSlidingPanel()"
      />}
    </app-slide-panel>
  `,
  styles: [``],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaManageSliderComponent {
  route = inject(ActivatedRoute);
  router = inject(Router);
  encryptionService = inject(EncryptionService);
  params$ = this.route.params.pipe(takeUntilDestroyed());

  mediaStore = inject(MediaStore);

  mediaId = signal<string>('');

  media = computed(() => this.mediaStore.getMediaById(this.mediaId()));

  constructor() {
    this.params$.subscribe((params) => {
      const { mediaId } = params;
      this.mediaId.set(this.encryptionService.decrypt(mediaId));
    });
    inject(SlidePanelService).setPanelComponentId(this.mediaId());
  }

  closeSlidingPanel() {
    // this navigates to the current path before 2 slashes, example:
    // current path: 'devices/manage/<channelId>
    // target path: 'devices
    this.router.navigate(['../../'], { relativeTo: this.route });
  }
}
