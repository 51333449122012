import { Component, OnInit } from '@angular/core';
import { environment } from '@desquare/environments';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [TranslateModule],
  selector: 'app-registration-error',
  templateUrl: './registration-error.component.html',
  styleUrls: ['./registration-error.component.scss'],
})
export class RegistrationErrorComponent implements OnInit {
  logoSrc = environment.assets.logoSmall;

  constructor() {}

  ngOnInit() {}
}
