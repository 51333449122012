<div class="h-100 d-flex flex-column">
  <!-- upTime Graph -->
  <div class="d-flex justify-content-center py-2">
    <div
      class="mx-2 btn-group btn-group-sm"
      name="uptimeTimespan"
      aria-label="Set the time span for the graph"
      style="height: fit-content"
    >
      <input
        class="btn-check"
        type="radio"
        [checked]="timespanMinutes === 30"
        [value]="30"
      />
      <label
        for="folderSearch"
        class="btn btn-outline-light"
        ngbTooltip="{{ 'SEARCH_FOLDER_TT' | translate }}"
        (click)="timespanMinutes = 30"
      >
        <div>last 30 {{ 'MINUTES' | translate }}</div>
      </label>

      <input
        class="btn-check"
        type="radio"
        [checked]="timespanMinutes === 120"
        [value]="120"
      />
      <label
        class="btn btn-outline-light"
        ngbTooltip="{{ 'SEARCH_GLOBAL_TT' | translate }}"
        (click)="timespanMinutes = 120"
      >
        <div>last 2 {{ 'HOURS' | translate }}</div>
      </label>
      <input
        class="btn-check"
        type="radio"
        [checked]="timespanMinutes === 720"
        [value]="720"
      />
      <label
        class="btn btn-outline-light"
        ngbTooltip="{{ 'SEARCH_GLOBAL_TT' | translate }}"
        (click)="timespanMinutes = 720"
      >
        <div>last 12 {{ 'HOURS' | translate }}</div>
      </label>
    </div>
    <div>
      <button class="btn btn-sm btn-outline-light" (click)="openModal()">
        {{ 'HISTORY_REPORT' | translate }}
      </button>
    </div>
  </div>
  <div class="overflow-y-auto overflow-x-hidden">
    <div
      class=""
      *ngIf="deviceStatusDataForEchart$ | async as deviceStatusDataForEchart"
    >
      <div class="p-2 d-flex justify-content-between">
        <h5>{{ 'UPTIME' | translate }}</h5>
      </div>
      <designage-echart
        [chartType]="ChartType.UPTIME"
        [data]="deviceStatusDataForEchart"
        [timespanMinutes]="timespanMinutes"
      ></designage-echart>
    </div>

    <!-- TEMP Graph -->
    <ng-container
      class=" "
      *ngIf="deviceTempDataForEchart$ | async as tempData"
    >
      <ng-container *ngIf="tempData.length > 0; else noTempData">
        <div class="p-2 d-flex justify-content-between">
          <h5>{{ 'SYSTEM_TEMP' | translate }}</h5>
        </div>
        <designage-echart
          [chartType]="ChartType.TEMP"
          [data]="tempData"
          [timespanMinutes]="timespanMinutes"
        ></designage-echart>
      </ng-container>
      <ng-template #noTempData>
        <div class="d-flex justify-content-center align-items-center">
          <h6>
            {{ 'THIS_DEVICE_NOT_REPORT' | translate }}
            {{ 'SYSTEM_TEMP' | translate }}
          </h6>
        </div>
      </ng-template>
    </ng-container>

    <!-- CPU Graph -->
    <ng-container class=" " *ngIf="deviceCpuDataForEchart$ | async as cpuData">
      <ng-container *ngIf="cpuData.length > 0; else noCpuData">
        <h5 class="p-2">{{ 'CPU_USAGE' | translate }}</h5>
        <designage-echart
          [chartType]="ChartType.CPU"
          [data]="cpuData"
          [timespanMinutes]="timespanMinutes"
        ></designage-echart>
      </ng-container>
      <ng-template #noCpuData>
        <div class="d-flex justify-content-center align-items-center">
          <h6>
            {{ 'THIS_DEVICE_NOT_REPORT' | translate }}
            {{ 'CPU_USAGE' | translate }}
          </h6>
        </div>
      </ng-template>
    </ng-container>
  </div>
</div>

<ng-template #onlineStatusModal></ng-template>
