import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SlidePanelComponent } from '@designage/app/shared/slide-panel/slide-panel.component';
import { ConfirmDialogComponent } from '@desquare/components/common/src/modals/confirm-dialog.component';
import {
  CurrentUserService,
  PlaylistViewService,
  PlaylistEditorService,
} from '@desquare/services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PlaylistCreationComponent } from '../playlist-creation/playlist-creation.component';

@Component({
  standalone: true,
  imports: [SlidePanelComponent, PlaylistCreationComponent],
  selector: 'app-playlist-create-panel',
  template: `<app-slide-panel
    (closePanel)="closePlaylistCreatePanel()"
    [fullscreenTooltip]="'TOGGLE_PLAYLIST_TABLE'"
    [closePanelTooltip]="'CLOSE_THIS_PLAYLIST'"
    [fullscreen]="true"
    [panelContent]="'playlistCreate'"
  >
    <app-playlist-creation> </app-playlist-creation>
  </app-slide-panel> `,
})
export class PlaylistCreatePanelComponent {
  constructor(
    private router: Router,
    private playlistViewService: PlaylistViewService,
    private modalService: NgbModal,
    private playlistEditorService: PlaylistEditorService,
    private currentUserService: CurrentUserService
  ) {}

  async canDeactivate(): Promise<boolean> {
    return this.playlistViewService.hasUnsavedPlaylistChanges
      ? this.openUnsavedConfirmationDialog()
      : true;
  }

  closePlaylistCreatePanel() {
    this.router.navigate(['playlists/']);
  }

  async openUnsavedConfirmationDialog() {
    const modalRef = this.modalService.open(ConfirmDialogComponent, {
      size: 'sm',
    });

    modalRef.componentInstance.headerText = 'Leave the page?';
    modalRef.componentInstance.bodyText =
      'There are unsaved changes, are you sure?';
    modalRef.componentInstance.dismissButtonText = 'CANCEL';
    modalRef.componentInstance.closeButtonText = 'CLOSE_WITHOUT_SAVING';
    modalRef.componentInstance.useDangerButton = true;

    return await modalRef.result.then(
      (value) => {
        this.playlistViewService.hasUnsavedPlaylistChanges = false;

        // this prevents the unsaved changes info banner to show (backup system of playlist)
        // it is alright to remove the backup since the user at this point on purposely left
        // the playlist manage page
        this.removePendingPlaylist();

        return value;
      },
      () => {
        return false;
      }
    );
  }

  removePendingPlaylist() {
    this.playlistEditorService.deletePendingPlaylist({
      profileId: this.currentUserService.currentProfile?.id,
      playlistId: undefined,
      // note: fresh playlists from create playlist page have
      // an id === undefined
    });
  }
}
