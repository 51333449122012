import {
  Component,
  Input,
  Output,
  EventEmitter,
  input,
  ChangeDetectionStrategy,
  computed,
} from '@angular/core';
import {
  PlaylistStatus,
  Playlist,
  ForcePublishDevicePlaylistGQL,
  PlaylistBulkAction,
  Layout,
} from '@designage/gql';
import { getPlaylistStatus } from '@desquare/utils';
import { PlaylistScheduleStatus } from '@desquare/enums';
import { CurrentUserService, ToasterService } from '@desquare/services';
import moment from 'moment';
import {
  NgbAccordionModule,
  NgbDropdownModule,
  NgbModal,
  NgbTooltip,
} from '@ng-bootstrap/ng-bootstrap';
import { IPlaylistRegion } from '@desquare/interfaces';
import { PlaylistManageDialogComponent } from '@desquare/components/common/src/modals/playlist-manage-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { AssetRowComponent } from '@designage/app/playlist-sequence/asset-row/asset-row.component';
import { LayoutExplorerComponent } from '@desquare/components/common/src/layout-explorer/layout-explorer.component';
import { DateProxyPipe } from '@desquare/components/common/src/pipe/pipe/date-proxy.pipe';
import { lastValueFrom } from 'rxjs';

@Component({
  standalone: true,
  imports: [
    TranslateModule,
    NgbAccordionModule,
    NgbDropdownModule,
    NgbTooltip,
    AssetRowComponent,
    LayoutExplorerComponent,
    DateProxyPipe,
  ],
  selector: 'app-playlist-row',
  templateUrl: './playlist-row.component.html',
  styleUrls: ['./playlist-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlaylistRowComponent {
  playlist = input.required<Playlist>();
  layout = input<Layout>();
  playlistRegions = input<IPlaylistRegion[]>();

  @Input() deviceId!: string;
  @Input() hideAccordion = false;

  /** this indicates if the playlist is loaded into device */
  @Input() loaded = false;
  @Input() channelId!: string;
  /** currently assigned region 
  @Input() region?: string;
  /** assignable regions */

  /** parent is showing or not layout regions */
  @Input() showLayoutRegions: boolean = false;

  @Output() loading = new EventEmitter<boolean>();
  @Output() loaderMessage = new EventEmitter<string>();
  @Output() removePlaylistId = new EventEmitter<string>();
  @Output() updatePlaylistRegion = new EventEmitter<IPlaylistRegion>();
  @Output() updatePlaylist = new EventEmitter<undefined>();

  accordionActiveId = 'accordion-playlist-row';
  activityStatus = computed(() =>
    getPlaylistStatus(this.playlist().startDate, this.playlist().endDate)
  );

  get playlistIsLoadedByDevice() {
    return this.deviceId && this.loaded;
  }
  get isForcePublishShown() {
    // TODO: work on feedback from device playlist load
    return false; // this.isUserSuperAdmin && !this.playlistIsLoadedByDevice;
  }

  get devicePlaylistStatus() {
    return !this.playlistIsLoadedByDevice
      ? 'OUT_OF_SYNC'
      : this.playlist().status;
  }

  get statusSuccess() {
    return (
      this.devicePlaylistStatus === PlaylistStatus.Published ||
      this.devicePlaylistStatus === PlaylistStatus.Active
    );
  }
  get statusDanger() {
    return !this.statusSuccess;
  }

  get isUserSuperAdmin() {
    return this.currentUserService.isSuperAdmin;
  }

  get scheduleStatus() {
    let ret = PlaylistScheduleStatus.NOT_SCHEDULED;
    if (this.playlist().startDate || this.playlist().endDate) {
      const yesterday = moment(Date.now()).add(-1, 'd');
      const tomorrow = moment(Date.now()).add(1, 'd');
      if (
        moment(Date.now()) < moment(this.playlist().startDate || tomorrow) || // not yet started
        moment(Date.now()) > moment(this.playlist().endDate || yesterday) // already ended
      ) {
        ret = PlaylistScheduleStatus.SCHEDULED;
      } else {
        ret = PlaylistScheduleStatus.PLAYING_NOW;
      }
      return ret;
    }
  }

  layoutRegions = computed(
    () =>
      this.layout()
        ?.source?.regionBlocks?.map((x) => x?.regionName)
        .filter((x) => x !== '')
        .sort() || []
  );

  get isDefaultRegion() {
    return (this.playlist().region || '') === '';
  }
  get canChangeRegion() {
    return this.layoutRegions()?.length > 0 || this.mustUpdateRegion;
  }

  /** current region is NOT compatible with layout regions list */
  get mustUpdateRegion() {
    const region = this.playlist().region || '';
    return this.isDefaultRegion
      ? false
      : ((this.layoutRegions() || []).length === 0 && region !== '') ||
          (this.layoutRegions()?.length > 0 &&
            !this.layoutRegions()?.includes(region));
  }
  constructor(
    private currentUserService: CurrentUserService,
    private forcePublishDevicePlaylist: ForcePublishDevicePlaylistGQL,
    private toasterService: ToasterService,
    private modalService: NgbModal
  ) {}

  redirectToPage() {
    console.log(
      'redirectToPage',
      this.playlist().parentPlaylist?.id || this.playlist().id
    );

    // const id = this.encryptionService.encrypt(
    //   this.playlist().parentPlaylist?.id || this.playlist().id
    // );
    // this.router.navigate(['playlists/manage', id]);

    const modal = this.modalService.open(PlaylistManageDialogComponent, {
      backdrop: 'static',
      windowClass: 'cesdk-modal',
      keyboard: false,
    });

    modal.componentInstance.playlistId =
      this.playlist().parentPlaylist?.id || this.playlist().id;

    modal.result
      .then(
        (result) => {
          this.updatePlaylist.emit();
        },
        (reason) => {}
      )
      .catch((err) => console.error('error', err));
  }

  async forcePublish() {
    this.loading.emit(true);
    this.loaderMessage.emit('FORCE_PUBLISHING_PLAYLIST');

    await lastValueFrom(
      this.forcePublishDevicePlaylist.mutate({
        ids: [this.playlist().parentPlaylist?.id || this.playlist().id],
        action: PlaylistBulkAction.PublishSelected,
        input: {
          deviceIds: [this.deviceId],
        },
      })
    ).then(({ data }) => {
      if (data) {
        this.toasterService.success('FORCE_PUBLISH_SUCCESS');
      } else {
        this.toasterService.error('FORCE_PUBLISH_FAIL');
      }
      this.loading.emit(false);
    });
  }

  removePlaylist() {
    this.removePlaylistId.emit(this.playlist().id);
  }

  setRegion(region: string) {
    console.log('setRegion', region);
    this.updatePlaylistRegion.emit({
      playlistId: this.playlist().id,
      region,
    });
  }
  resetRegion() {
    this.setRegion('');
  }
}
