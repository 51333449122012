<form [formGroup]="mediaForm" (submit)="onSubmit(mediaForm.value)">
  <div class="row">
    <div class="col mt-2">
      <label class="form-label" for="name">{{ 'NAME' | translate }}:</label>
      @if(mediaForm && !mediaForm.controls['name'].pristine &&
      mediaForm.controls['name'].hasError('required')){
      <span class="error">
        {{ 'NAME_IS_REQUIRED' | translate }}
      </span>
      }
      <input
        type="text"
        name="name"
        class="form-control form-control-dark"
        formControlName="name"
      />
    </div>
  </div>
  <div class="d-flex justify-content-between flex-wrap pt-3 px-2 gap-2">
    <button class="btn btn-outline-success" type="submit">
      {{ 'SAVE' | translate }}
    </button>
    @if(canEdit && media().type === 'IMAGE' && media().metadata?.format !=
    'pdf'){
    <button class="btn btn-outline-success" type="button" (click)="editMedia()">
      {{ 'EDIT' | translate }}</button
    >} @if(canEdit && media().type === 'VIDEO'){
    <button class="btn btn-outline-success" type="button" (click)="editMedia()">
      {{ 'EDIT' | translate }}</button
    >}

    <button
      class="btn btn-outline-primary"
      type="button"
      ngbTooltip="Move to a different folder"
      (click)="moveMediaFolder()"
    >
      {{ 'MOVE' | translate }}
    </button>
    <button
      type="button"
      class="btn btn-outline-danger"
      (click)="deleteMediaDialog()"
    >
      {{ 'DELETE' | translate }}
    </button>
    @if(downloadLink()){
    <a
      [href]="downloadLinkAttachment()"
      [download]="downloadLinkBeautyName()"
      role="button"
      class="btn btn-outline-success ms-auto"
      target="_blank"
    >
      {{ 'DOWNLOAD' | translate }} </a
    >}
  </div>
</form>
