import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild,
  inject,
} from '@angular/core';
import { SubSink } from 'subsink';
import {
  Maybe,
  UpdateChannelGQL,
  UpdateChannelMutation,
  DeleteChannelGQL,
  Channel,
} from '@designage/gql';
import {
  ToasterService,
  CurrentUserService,
  ResponsiveUiService,
  ChannelService,
  DeviceDataService,
} from '@desquare/services';
import { Router } from '@angular/router';
import { ApolloError } from '@apollo/client/errors';
import { IChannelForm } from '@desquare/interfaces';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteChannelDialogComponent } from '@desquare/components/common/src/modals/delete-channel-dialog.component';
import { ChannelFormComponent } from '../channel-form/channel-form.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';
import { ChannelsStore } from '@desquare/stores';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    ReactiveFormsModule,
    ChannelFormComponent,
  ],
  selector: 'app-channel-settings',
  templateUrl: './channel-settings.component.html',
  styleUrls: ['./channel-settings.component.scss'],
})
export class ChannelSettingsComponent implements OnDestroy {
  channelService = inject(ChannelService);
  channelsStore = inject(ChannelsStore);
  deviceDataService = inject(DeviceDataService);

  @ViewChild(ChannelFormComponent)
  channelFormComponent!: ChannelFormComponent;

  private subs = new SubSink();

  @Input() isDashboardView = false;
  @Input() headerTitle?: string;
  @Input() profileId?: Maybe<string>;
  @Input() ownerId?: string;
  @Input() channel?: Maybe<UpdateChannelMutation['updateChannel']['channel']>;
  @Output() loading = new EventEmitter<boolean>();
  @Output() loaderMessage = new EventEmitter<string>();
  @Output() channelUpdated = new EventEmitter<
    UpdateChannelMutation['updateChannel']['channel']
  >();
  @Output() resetChannelForm = new EventEmitter();
  @Output() values = new EventEmitter<IChannelForm>();

  isFormValid = false;
  isFormPristine = false;
  deletingChannel: boolean = false;

  constructor(
    private updateChannelGQL: UpdateChannelGQL,
    private deleteChannelGQL: DeleteChannelGQL,
    private currentUserService: CurrentUserService,
    private toasterService: ToasterService,
    private modalService: NgbModal,
    private router: Router,
    public responsiveUiService: ResponsiveUiService
  ) {}

  get showDeleteButton() {
    return this.currentUserService.canManageChannels;
  }

  get saveEnabled() {
    return this.isFormValid && !this.isFormPristine;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  updateChannel(form: IChannelForm) {
    if (this.channel) {
      this.loading.emit(true);
      this.loaderMessage.emit('UPDATING_CHANNEL');

      lastValueFrom(
        this.updateChannelGQL.mutate({
          input: {
            id: form.id,
            name: form.name,
            description: form.description,
            layoutId: form.layoutId,
          },
        })
      )
        .then(({ data }) => {
          if (
            data &&
            data.updateChannel.isSuccessful &&
            data.updateChannel.channel
          ) {
            this.toasterService.success('UPDATE_CHANNEL_SUCCESS');
            this.channel = data.updateChannel.channel;
            this.channelUpdated.emit(this.channel);
            this.channelsStore.updateChannel(this.channel as Channel);
            this.deviceDataService.refreshDevices();
          } else {
            this.toasterService.error('UNKNOWN_ERROR');
          }
          this.isFormPristine = true;
          this.loading.emit(false);
        })
        .catch((error: ApolloError) => {
          error.graphQLErrors.forEach((gqlError) => {
            this.toasterService.handleGqlError(gqlError);
          });
          this.loading.emit(false);
        });
    }
  }

  openDeleteChannelDialog() {
    if (this.channel) {
      const modal = this.modalService.open(DeleteChannelDialogComponent, {
        backdrop: 'static',
      });
      modal.componentInstance.channel = this.channel;
      modal.result
        .then(() => {
          this.deleteChannel();
        })
        .catch(() => {});
    }
  }

  resetForm() {
    this.channelFormComponent.resetForm();
  }

  deleteChannel() {
    if (this.channel && this.channel.id) {
      this.deletingChannel = true;

      lastValueFrom(
        this.deleteChannelGQL.mutate({
          id: this.channel.id,
        })
      ).then(
        ({ data }) => {
          if (data && data.deleteChannel.isSuccessful) {
            this.toasterService.success('DELETE_CHANNEL_SUCCESS');
            this.navigateToChannelList();
            this.channelService.refreshChannels();
            this.deviceDataService.refreshDevices();
          } else {
            this.toasterService.error('UNKNOWN_ERROR');
          }
        },
        (error: ApolloError) => {
          error.graphQLErrors.forEach((gqlError) => {
            this.toasterService.handleGqlError(gqlError);
          });
        }
      );
    }
  }

  navigateToChannelList() {
    this.router.navigate(['/channels']);
  }

  onLoadingChanged(value: boolean) {
    this.loading.emit(value);
  }

  onLoaderMessageChanged(value: string) {
    this.loaderMessage.emit(value);
  }
}
