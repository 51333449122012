import {
  Component,
  Output,
  EventEmitter,
  computed,
  input,
  signal,
  ChangeDetectionStrategy,
  inject,
  effect,
  NgZone,
  viewChild,
} from '@angular/core';
import {
  NgbAccordionModule,
  NgbDropdownModule,
  NgbTimeAdapter,
  NgbTimepicker,
  NgbTooltip,
} from '@ng-bootstrap/ng-bootstrap';
import { DeviceStatusInfo } from '@desquare/models';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DateProxyPipe } from '@desquare/components/common/src/pipe/pipe/date-proxy.pipe';
import { DeviceData } from '@desquare/types';
import { animate, style, transition, trigger } from '@angular/animations';
import {
  JsonPipe,
  KeyValuePipe,
  NgClass,
  NgStyle,
  NgTemplateOutlet,
  SlicePipe,
  TitleCasePipe,
} from '@angular/common';
import {
  NgbTimeStringAdapter,
  ProfileSettingsService,
} from '@desquare/services';
import { TypeaheadComponent } from '@desquare/components/common/src/typeahead/typeahead.component';
import { timeZonesNames } from '@vvo/tzdb';
import {
  Channel,
  DeviceOrientation,
  ScheduleDays,
  ScheduleDisplay,
} from '@designage/gql';
import { CopyToClipboardComponent } from '@desquare/components/common/src/copy-to-clipboard/copy-to-clipboard.component';
import { displayScheduleTemplate } from '../displaySchedModel';
import { IDisplayScheduleDays, weekdaysSortOrder } from '@desquare/interfaces';
import { cloneDeep } from 'lodash';
import { NgxSliderModule, Options } from '@angular-slider/ngx-slider';
import { DeviceVolumeComponent } from '@desquare/components/common/src/device-ui-components/device-volume.component';
import { DeviceBrightnessComponent } from '@desquare/components/common/src/device-ui-components/device-brightness.component';
import { formatTimeHMSToHM, formatTimeHMToHMS } from '@desquare/utils';
import { FormDirective } from '@desquare/directives';
import { OrderByPipe } from '@desquare/components/common/src/pipe/orderBy/orderBy.pipe';

@Component({
  standalone: true,
  imports: [
    FormsModule,
    NgStyle,
    NgClass,
    NgbTooltip,
    NgTemplateOutlet,
    TitleCasePipe,
    SlicePipe,
    DateProxyPipe,
    KeyValuePipe,
    TranslateModule,
    NgxSliderModule,
    NgbAccordionModule,
    NgbTimepicker,
    NgbDropdownModule,
    TypeaheadComponent,
    CopyToClipboardComponent,
    DeviceVolumeComponent,
    DeviceBrightnessComponent,
    FormDirective,
    OrderByPipe,
  ],
  selector: 'app-device-manage-settings',
  templateUrl: './device-manage-settings.component.html',
  styleUrls: ['./device-manage-settings.component.scss'],
  animations: [
    trigger('editSettingsControls', [
      transition(':enter', [
        style({ right: '-10rem' }),
        animate('0.25s ease-out', style({ right: '*' })),
      ]),
      transition(':leave', [
        style({ right: '*' }),
        animate('0.25s ease-in', style({ right: '-10rem' })),
      ]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: NgbTimeAdapter, useClass: NgbTimeStringAdapter }],
})
export class DeviceManageSettingsComponent {
  profileSettingsService = inject(ProfileSettingsService);
  ngZone = inject(NgZone);

  timezoneTypeahead = viewChild<TypeaheadComponent>('timezoneTypeahead');

  protected readonly device = input.required<DeviceData>();
  isUserSuperAdmin = input<boolean>(false);
  saving = input<boolean>(false);
  profileChannels = input.required<{ id: string; name: string }[]>();

  timezones = timeZonesNames;
  orientations = Object.keys(DeviceOrientation);
  sliderOptions: Options = {
    floor: 1,
    ceil: 100,
  };

  @Output() cancel = new EventEmitter<void>();
  @Output() save = new EventEmitter<DeviceData>();

  isEditingSettings = signal<boolean>(false);

  deviceSettings = signal<DeviceData>({
    updatedAt: new Date(),
    createdAt: new Date(),
    id: '',
    name: '',
  });

  protected readonly viewModel = computed(() => ({
    deviceSettings: this.deviceSettings(),
  }));

  protected get vm() {
    return this.viewModel();
  }
  isDeviceOnline = computed(
    () => this.device().status === DeviceStatusInfo.Online
  );

  // constructor() {
  //   effect(() => {
  //     console.log('viewModel', this.viewModel());
  //   });
  // }
  formValueChange(value: DeviceData) {
    this.deviceSettings.set(value);
  }

  onEditSettings() {
    this.isEditingSettings.set(true);
    this.deviceSettings.set(cloneDeep(this.device()));
    // console.log('deviceSettings', this.deviceSettings());
  }

  onNameChange(value: string) {
    this.deviceSettings.update((state) => ({
      ...state,
      name: value,
    }));
  }
  onChannelChange(channel: Channel) {
    console.log('onChannelChange', channel);

    this.deviceSettings.update((state) => ({
      ...state,
      channelId: channel.id,
      channel: {
        id: channel.id,
        name: channel.name,
      },
    }));
  }

  onKioskModeChange() {
    this.deviceSettings.update((state) => ({
      ...state,
      advancedSettings: {
        ...state.advancedSettings,
        kioskMode: !state.advancedSettings?.kioskMode,
      },
    }));
  }

  toggleRebootSchedule() {
    this.deviceSettings.update((state) => ({
      ...state,
      advancedSettings: {
        ...state.advancedSettings,
        scheduleDailyReboot: !state.advancedSettings?.scheduleDailyReboot,
        rebootTime: state.advancedSettings?.scheduleDailyReboot
          ? state.advancedSettings?.rebootTime
          : '03:00:00',
      },
    }));
  }

  onRebootTimeChange(rebootTime: string) {
    this.deviceSettings.update((state) => ({
      ...state,
      advancedSettings: {
        ...state.advancedSettings,
        rebootTime: formatTimeHMToHMS(rebootTime),
      },
    }));
  }

  getProfileTimezone() {
    const profileTimezone =
      this.profileSettingsService.getProfileTimezoneValue().timezone;
    this.timezoneTypeahead()?.setItem(profileTimezone);
    this.updateTimezone(profileTimezone);
  }

  updateTimezone(timeZone: string) {
    this.deviceSettings.update((state) => ({
      ...state,
      advancedSettings: {
        ...state.advancedSettings,
        timeZone,
      },
    }));
  }

  onNtpServerChange(ntpServer: string) {
    this.deviceSettings.update((state) => ({
      ...state,
      advancedSettings: {
        ...state.advancedSettings,
        ntpServer,
      },
    }));
  }

  onOrientationChange(orientation: DeviceOrientation) {
    this.deviceSettings.update((state) => ({
      ...state,
      advancedSettings: {
        ...state.advancedSettings,
        orientation,
      },
    }));
  }

  onFlipScreenshotChange() {
    this.deviceSettings.update((state) => ({
      ...state,
      advancedSettings: {
        ...state.advancedSettings,
        flipScreenshot: !state.advancedSettings?.flipScreenshot || false,
      },
    }));
  }

  onVolumeChange(volume: number) {
    this.deviceSettings.update((state) => ({
      ...state,
      advancedSettings: {
        ...state.advancedSettings,
        volume,
      },
    }));
  }

  sortWeekdays(weekdays: IDisplayScheduleDays) {
    let ordered: any[] = [];
    Object.keys(weekdays).forEach((key) => {
      let value = weekdays[key as keyof IDisplayScheduleDays];
      let index = weekdaysSortOrder[key as keyof typeof weekdaysSortOrder];
      ordered[index] = {
        key: key,
        value: value,
      };
    });
    return ordered;
  }

  switchDaySchedule(scheduleIndex: number, day: keyof ScheduleDays) {
    this.deviceSettings.update((state) => {
      if (
        state.advancedSettings?.scheduleDisplay &&
        state.advancedSettings?.scheduleDisplay[scheduleIndex]
      ) {
        const newScheduleArray: ScheduleDisplay[] = [
          ...state.advancedSettings.scheduleDisplay,
        ];
        const daySchedule = newScheduleArray[scheduleIndex].days as Record<
          keyof ScheduleDays,
          boolean | undefined
        >;
        daySchedule[day] = !daySchedule[day];
        // console.log(
        //   'onCancel device',
        //   JSON.stringify(this.device().advancedSettings?.scheduleDisplay)
        // );

        return {
          ...state,
          advancedSettings: {
            ...state.advancedSettings,
            scheduleDisplay: newScheduleArray,
          },
        };
      }
      return state;
    });
  }

  setDisplaySchedule(
    operation: 'on' | 'off',
    scheduleIndex: number,
    time: string
  ) {
    this.deviceSettings.update((state) => {
      if (
        state.advancedSettings?.scheduleDisplay &&
        state.advancedSettings?.scheduleDisplay[scheduleIndex]
      ) {
        const newScheduleArray: ScheduleDisplay[] = [
          ...state.advancedSettings.scheduleDisplay,
        ];
        newScheduleArray[scheduleIndex] = {
          ...newScheduleArray[scheduleIndex],
          [operation]: formatTimeHMToHMS(time),
        };
        return {
          ...state,
          advancedSettings: {
            ...state.advancedSettings,
            scheduleDisplay: newScheduleArray,
          },
        };
      }
      return state;
    });
  }

  deleteDisplayScheduleRow(scheduleIndex: number) {
    this.deviceSettings.update((state) => ({
      ...state,
      advancedSettings: {
        ...state.advancedSettings,
        scheduleDisplay: [
          ...(state.advancedSettings?.scheduleDisplay || []),
        ].filter((x, i) => i !== scheduleIndex),
      },
    }));
  }

  addDisplayScheduleRow() {
    this.deviceSettings.update((state) => ({
      ...state,
      advancedSettings: {
        ...state.advancedSettings,
        scheduleDisplay: [
          ...(state.advancedSettings?.scheduleDisplay || []),
          displayScheduleTemplate(),
        ],
      },
    }));
  }

  toggleBrightnessSchedule(e: Event) {
    const ischecked = (<HTMLInputElement>e.target).checked;
    this.deviceSettings.update((state) => ({
      ...state,
      advancedSettings: {
        ...state.advancedSettings,
        scheduleBrightness: {
          ...state.advancedSettings?.scheduleBrightness,
          brightness1:
            state.advancedSettings?.scheduleBrightness?.brightness1 || 100,
          brightness2:
            state.advancedSettings?.scheduleBrightness?.brightness2 || 100,
          time1:
            state.advancedSettings?.scheduleBrightness?.time1 || '00:00:00',
          time2:
            state.advancedSettings?.scheduleBrightness?.time2 || '00:00:00',
        },
      },
    }));
    if (ischecked === true)
      this.deviceSettings.update((state) => ({
        ...state,
        advancedSettings: {
          ...state.advancedSettings,
          scheduleBrightness: {
            ...state.advancedSettings?.scheduleBrightness,
            brightness1:
              state.advancedSettings?.scheduleBrightness?.brightness1 || 100,
            brightness2: Math.floor(
              (state.advancedSettings?.scheduleBrightness?.brightness1 || 75) -
                50
            ),
          },
        },
      }));
    else
      this.deviceSettings.update((state) => ({
        ...state,
        advancedSettings: {
          ...state.advancedSettings,
          scheduleBrightness: {
            ...state.advancedSettings?.scheduleBrightness,
            brightness2:
              state.advancedSettings?.scheduleBrightness?.brightness1,
          },
        },
      }));
  }

  onBrightnessChange(
    slider: 'common' | 'brightness1' | 'brightness2',
    value: number
  ) {
    console.log('onBrightnessChange', slider, value);

    if (slider === 'common') {
      this.deviceSettings.update((state) => ({
        ...state,
        advancedSettings: {
          ...state.advancedSettings,
          scheduleBrightness: {
            ...state.advancedSettings?.scheduleBrightness,
            brightness1: value,
            brightness2: value,
          },
        },
      }));
    } else {
      this.deviceSettings.update((state) => ({
        ...state,
        advancedSettings: {
          ...state.advancedSettings,
          scheduleBrightness: {
            ...state.advancedSettings?.scheduleBrightness,
            [slider]: value,
          },
        },
      }));
    }
  }

  onBrightnessTimeChange(operation: 'time1' | 'time2', time: string) {
    this.deviceSettings.update((state) => ({
      ...state,
      advancedSettings: {
        ...state.advancedSettings,
        scheduleBrightness: {
          ...state.advancedSettings?.scheduleBrightness,
          [operation]: formatTimeHMToHMS(time),
        },
      },
    }));
  }

  toggleSynchronize() {
    this.deviceSettings.update((state) => ({
      ...state,
      advancedSettings: {
        ...state.advancedSettings,
        synchronize: !state.advancedSettings?.synchronize,
      },
    }));
  }

  toggleSyncAutoGroup() {
    this.deviceSettings.update((state) => ({
      ...state,
      advancedSettings: {
        ...state.advancedSettings,
        manualSyncGroup: !state.advancedSettings?.manualSyncGroup,
      },
    }));
  }

  onSyncGroupChange(syncGroup: string) {
    this.deviceSettings.update((state) => ({
      ...state,
      advancedSettings: {
        ...state.advancedSettings,
        syncGroup,
      },
    }));
  }

  onMasterSyncDelayChange(masterSyncDelay: number) {
    this.deviceSettings.update((state) => ({
      ...state,
      advancedSettings: {
        ...state.advancedSettings,
        masterSyncDelay,
      },
    }));
  }

  onSubmit() {
    this.save.emit(this.vm.deviceSettings);
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        this.isEditingSettings.set(false);
      }, 50);
    });
  }

  onCancel() {
    this.deviceSettings.set(cloneDeep(this.device()));
    console.log('onCancel', this.vm.deviceSettings);

    this.isEditingSettings.set(false);
    this.cancel.emit();
  }
}
