import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { SubSink } from 'subsink';
import {
  GetLocationForLocationManagePageQuery,
  Maybe,
  GetLocationForLocationManagePageGQL,
} from '@designage/gql';
import { ActivatedRoute } from '@angular/router';
import {
  EncryptionService,
  CurrentUserService,
  ToasterService,
  SlidePanelService,
} from '@desquare/services';
import { ApolloError } from '@apollo/client/errors';
import { LocationDetailComponent } from '../location-detail/location-detail.component';
import { LocationSettingsComponent } from '../location-settings/location-settings.component';
import { LoaderComponent } from '@desquare/components/common/src/loader/loader.component';

enum Tabs {
  SETTINGS,
  DETAILS,
}
@Component({
  standalone: true,
  imports: [
    LoaderComponent,
    LocationSettingsComponent,
    LocationDetailComponent,
  ],
  selector: 'app-location-manage',
  templateUrl: './location-manage.component.html',
  styleUrls: ['./location-manage.component.scss'],
})
export class LocationManageComponent implements OnInit, OnDestroy {
  private subs = new SubSink();

  currentTab!: Tabs;
  loaderMessage!: string;
  isFormValid = false;
  isFormPristine = false;
  loadingLocation = false;
  settingsLoading = false;
  tabs = Tabs;
  location!: Maybe<GetLocationForLocationManagePageQuery['location']>;

  constructor(
    private getLocationGQL: GetLocationForLocationManagePageGQL,
    private route: ActivatedRoute,
    private encryptionService: EncryptionService,
    private toasterService: ToasterService,
    private currentUserService: CurrentUserService,
    private slidePanelService: SlidePanelService
  ) {}

  get showSettingsNav() {
    return this.currentUserService.canManageLocations;
  }

  get loading() {
    return this.loadingLocation || this.settingsLoading;
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  ngOnInit() {
    this.initVariables();
  }

  initVariables() {
    this.currentTab = this.currentUserService.canManageLocations
      ? Tabs.SETTINGS
      : Tabs.DETAILS;

    this.subs.sink = this.route.paramMap.subscribe((params) => {
      const locationId = params.get('id');

      if (locationId) {
        this.initLocation(locationId);
      }
    });
  }

  async initLocation(id: string) {
    const decryptedId = this.encryptionService.decrypt(id);
    this.loaderMessage = 'FETCHING_LOCATION';
    this.loadingLocation = true;

    this.subs.sink = this.getLocationGQL
      .fetch({ id: decryptedId }, { fetchPolicy: 'no-cache' })
      .subscribe({
        next: ({ loading, data: { location }, errors }) => {
          this.loadingLocation = loading;
          if (location) {
            this.location = location;
          }
          this.slidePanelService.setPanelComponentId(decryptedId);
        },
        error: (error: ApolloError) => {
          this.loadingLocation = false;
          error.graphQLErrors.forEach((err) => {
            console.error('initLocation', err);
            this.toasterService.handleGqlError(err);
          });
        },
      });
  }
}
