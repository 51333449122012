import { Routes } from '@angular/router';
import { MainComponent } from './main/main.component';
import { AuthGuard } from '@auth0/auth0-angular';
import { LocationListComponent } from './location/location-list/location-list.component';
import { ResourceGroupsComponent } from './user/resource-groups/resource-groups.component';
import { ProfileUsersComponent } from './profile/profile-users/profile-users.component';
import {
  ChannelManageGuard,
  EditorGuard,
  LocationManageGuard,
  PlaylistManageDeactivateGuard,
  PlaylistManageGuard,
  ProfileManageGuard,
} from '@desquare/guards';
import { ChannelManageSliderComponent } from './channel/channel-manage-slider/channel-manage-slider.component';
import { LocationManageSliderComponent } from './location/location-manage-slider/location-manage-slider.component';
import { MediaManageSliderComponent } from './media/media-manage-slider/media-manage-slider.component';
import { LayoutMainComponent } from './layout/layout-main/layout-main.component';
import { LayoutCreatePanelComponent } from './layout/layout-create-panel/layout-create-panel.component';
import { LayoutManagePanelComponent } from './layout/layout-manage-panel/layout-manage-panel.component';
import { DeviceManageSliderComponent } from './device/device-manage-slider/device-manage-slider.component';
import { PlaylistCreatePanelComponent } from './playlist/playlist-create-panel/playlist-create-panel.component';
import { PlaylistManagePanelComponent } from './playlist/playlist-manage-panel/playlist-manage-panel.component';
import { UserManageSliderComponent } from './user/user-manage-slider/user-manage-slider.component';
import { RegistrationCompleteComponent } from './registration/registration-complete/registration-complete.component';
import { RegistrationErrorComponent } from './registration/registration-error/registration-error.component';
import { ProfileCreateComponent } from './profile/profile-create/profile-create.component';

export const routes: Routes = [
  {
    path: 'register',
    loadComponent: () =>
      import('./registration/registration.component').then(
        (m) => m.RegistrationComponent
      ),
    children: [
      {
        path: 'complete',
        component: RegistrationCompleteComponent,
      },
      {
        path: 'error',
        component: RegistrationErrorComponent,
      },
    ],
  },
  {
    path: 'auth0-callback',
    loadChildren: () =>
      import('./auth0-callback/auth0-callback.component').then(
        (m) => m.Auth0CallbackComponent
      ),
  },
  {
    path: '',
    loadComponent: () =>
      import('./main/main.component').then((m) => m.MainComponent),
    canLoad: [AuthGuard],
    children: [
      // MARK: Watchtower
      {
        path: 'watchtower',
        loadComponent: () =>
          import('./watch-tower/watch-tower.component').then(
            (m) => m.WatchTowerComponent
          ),
        data: {
          breadcrumb: 'WatchTower',
        },
      },
      // MARK: Profile
      {
        path: 'profile',
        data: { breadcrumb: { disable: true } },
        children: [
          {
            path: 'settings',
            loadComponent: () =>
              import(
                './profile/profile-settings/profile-settings.component'
              ).then((m) => m.ProfileSettingsComponent),
            canLoad: [ProfileManageGuard],
            data: {
              breadcrumb: 'Settings',
            },
          },
          {
            path: 'devices',
            loadComponent: () =>
              import('./device/device-list/device-list.component').then(
                (m) => m.DeviceListComponent
              ),
            data: {
              breadcrumb: 'Devices',
            },
            children: [
              {
                path: 'manage/:deviceId',
                component: DeviceManageSliderComponent,
                // canLoad: [ProfileManageGuard],
                data: {
                  breadcrumb: 'Manage',
                },
              },
            ],
          },
          {
            path: 'locations',
            component: LocationListComponent,
            data: {
              breadcrumb: 'Locations',
            },
            children: [
              {
                path: 'manage/:id/:profileId',
                component: LocationManageSliderComponent,
                canLoad: [LocationManageGuard],
                data: {
                  breadcrumb: 'Manage',
                },
              },
            ],
          },
          {
            path: 'users',
            component: ProfileUsersComponent,
            canLoad: [ProfileManageGuard],
            children: [
              {
                path: 'manage',
                // canLoad: [LocationManageGuard],
                data: {
                  breadcrumb: { skip: true },
                },
                children: [
                  {
                    path: ':userId',
                    component: UserManageSliderComponent,
                    // canLoad: [LocationManageGuard],
                    data: {
                      breadcrumb: { alias: 'userManage', skip: true },
                    },
                  },
                ],
              },
            ],
          },
          {
            path: 'resource-groups',
            component: ResourceGroupsComponent,
            data: {
              breadcrumb: 'Resource Groups',
            },
          },
          {
            path: 'create',
            component: ProfileCreateComponent,
            data: {
              breadcrumb: 'Create Profile',
            },
          },
        ],
      },
      // MARK: Media
      {
        path: 'media',
        loadComponent: () =>
          import('./media/media-list/media-list.component').then(
            (m) => m.MediaListComponent
          ),
        data: {
          breadcrumb: 'Media',
        },
        children: [
          {
            path: 'manage/:mediaId',
            component: MediaManageSliderComponent,
            canLoad: [ProfileManageGuard],
            data: {
              breadcrumb: 'Manage',
            },
          },
        ],
      },
      // MARK: Layouts
      {
        path: 'layouts',
        component: LayoutMainComponent,
        data: {
          breadcrumb: 'Layouts',
        },
        children: [
          {
            path: 'manage/:layoutId',
            component: LayoutManagePanelComponent,
            canLoad: [EditorGuard],
            data: {
              breadcrumb: 'Manage',
            },
          },
          {
            path: 'create',
            component: LayoutCreatePanelComponent,
            canLoad: [EditorGuard],
            data: {
              breadcrumb: 'Create',
            },
          },
        ],
      },
      // MARK: Playlists
      {
        path: 'playlists',
        loadComponent: () =>
          import('./playlist/playlist-list/playlist-list.component').then(
            (m) => m.PlaylistListComponent
          ),
        data: {
          breadcrumb: 'Playlists',
        },
        children: [
          {
            path: 'create/:type',
            component: PlaylistCreatePanelComponent,
            canLoad: [PlaylistManageGuard],
            data: {
              breadcrumb: 'CREATE_PLAYLIST',
            },
            canDeactivate: [PlaylistManageDeactivateGuard],
          },
          {
            path: 'manage',
            data: {
              breadcrumb: { skip: true },
            },
            children: [
              {
                path: ':playlistId',
                component: PlaylistManagePanelComponent,
                canLoad: [PlaylistManageGuard],
                data: {
                  breadcrumb: { alias: 'playlistManage', label: 'Manage' },
                },
                canDeactivate: [PlaylistManageDeactivateGuard],
              },
            ],
          },
        ],
      },
      // MARK: Channels
      {
        path: 'channels',
        loadComponent: () =>
          import('./channel/channel-list/channel-list.component').then(
            (m) => m.ChannelListComponent
          ),
        data: {
          breadcrumb: 'Channels',
        },
        children: [
          {
            path: 'manage',
            data: {
              breadcrumb: { skip: true },
            },
            children: [
              {
                path: ':channelId/:profileId',
                component: ChannelManageSliderComponent,
                canLoad: [ChannelManageGuard],
                data: {
                  breadcrumb: { alias: 'channelManage', label: 'Manage' },
                },
              },
            ],
          },
          // {
          //   path: 'detail/:channelId',
          //   component: ChannelDetailComponent,
          //   canLoad: [ChannelManageGuard],
          //   data: {
          //     breadcrumb: 'Detail',
          //   },
          // },
        ],
      },
      // MARK: Events
      {
        path: 'events',
        loadComponent: () =>
          import('./channel/event-list/event-list.component').then(
            (m) => m.EventListComponent
          ),
        data: {
          breadcrumb: 'Events',
        },
        children: [
          {
            path: 'manage',
            data: {
              breadcrumb: { skip: true },
            },
            children: [
              {
                path: ':channelId/:profileId',
                component: ChannelManageSliderComponent,
                canLoad: [ChannelManageGuard],
                data: {
                  breadcrumb: { alias: 'channelManage', label: 'Manage' },
                },
              },
            ],
          },
          // {
          //   path: 'detail/:channelId',
          //   component: ChannelDetailComponent,
          //   canLoad: [ChannelManageGuard],
          //   data: {
          //     breadcrumb: 'Detail',
          //   },
          // },
        ],
      },
      // MARK: Other Links
      {
        path: 'create',
        loadComponent: () =>
          import('./profile/profile-create/profile-create.component').then(
            (m) => m.ProfileCreateComponent
          ),
        canLoad: [ProfileManageGuard],
        data: {
          breadcrumb: 'Create Profile',
        },
      },
      { path: '', redirectTo: 'watchtower', pathMatch: 'full' },
      { path: 'dashboard', redirectTo: 'watchtower', pathMatch: 'full' },
      { path: '**', redirectTo: 'watchtower' },
    ],
  },
];
